import { Routes } from '@angular/router';
import { ResidentsoverviewComponent } from './residentsoverview.component';
import { ResidentdetailComponent } from './residentdetail.component';

export const routes: Routes = [
    {
        path: '',
        component: ResidentsoverviewComponent
    },
    {
        path: ':id',
        component: ResidentdetailComponent
    },
    {
        path: ':id/edit',
        component: ResidentdetailComponent
    },
    {
        path: 'create',
        component: ResidentdetailComponent
    }
];