import { Component, OnInit, ViewChild } from '@angular/core';

import * as c from '@core';
import * as m from '@models';
import { WorkOrderModalComponent } from './components/work-order-modal.component';

@Component({
    templateUrl: './overview.html',
})
export class OverviewComponent implements OnInit {
    @ViewChild('pagination')
    public pagination: c.PaginationComponent;

    @ViewChild('editModal') editModal: WorkOrderModalComponent;

    public allWorkOrders: m.WorkOrderReferenceDto[] = [];
    public filteredWorkOrders: m.WorkOrderReferenceDto[] = [];
    public searchDate: Date;
    public requesterId: number;
    public requesters: m.IdTextDto[] = [];
    public technicians: m.IdTextDto[] = [];

    public technicianId: number;

    public listFilter: string;

    public currentPage: number = 1;
    public pageSize: number = 10;
    public total: number;

    constructor(
        private dispatcher: c.Dispatcher
    ) {
    }

    ngOnInit() {
        this.refresh(true, true);
    }

    private _refreshTimeout;

    public delayedRefresh() {
        clearTimeout(this._refreshTimeout);
        this._refreshTimeout = setTimeout(() => this.refresh(), 300);
    }

    async refresh(resetPage = true, initialData = false) {
        if (resetPage) {
            this.currentPage = 1;
        }

        let request = new m.GetWorkOrdersRequest({
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            searchInput: this.listFilter,
            dateInput: this.searchDate,
            requesterId: this.requesterId,
            technicianId: this.technicianId,
            fetchInitialData: initialData
        });

        let response = await this.dispatcher.send<m.GetWorkOrdersResponse>(request);
        this.filteredWorkOrders = response.workOrders;
        this.total = response.count;
        if (initialData) {
            this.requesters = response.requesters;
            this.technicians = response.technicians;
        }
    }

    public pageChanged(event: number) {
        this.currentPage = event;
        this.refresh(false);
    }

    openWorkOrderModal(workorder: m.WorkOrderReferenceDto) {
        this.editModal.show(workorder);
    }

    async downloadWorkorder(id: number){
        let response = await this.dispatcher.send<m.DownloadWorkOrderResponse>(new m.DownloadWorkOrderRequest({ workOrderId: id }));
        window.open("/download-file?key=" + response.key);
    }
}
