import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetWorkOrdersByTechnicianRequest implements IRequest<GetWorkOrdersByTechnicianResponse> {
    constructor(init?: Partial<GetWorkOrdersByTechnicianRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.Performance.WorkOrders.GetWorkOrdersByTechnicianRequest';
}

export class GetWorkOrdersByTechnicianResponse extends ImversaResponse {
    currentWorkOrders: dtos.WorkOrderReferenceDto[];

    constructor(init?: Partial<GetWorkOrdersByTechnicianResponse>) {
        super();
        Object.assign(this, init);
    }
} 