import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class CreateAddressRequest implements IRequest<CreateAddressResponse> {
    constructor(init?: Partial<CreateAddressRequest>) {
        Object.assign(this, init);
    }

    street: string;
    bus: string;
    number: string;
    postalCode: string;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.AddressRequests.CreateAddressRequest';
}

export class CreateAddressResponse extends ImversaResponse {
    addressMini: dtos.AddressMiniDto;

    constructor(init?: Partial<CreateAddressResponse>) {
        super();
        Object.assign(this, init);
    }
} 