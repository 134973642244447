<app-modal #myUserEditModal>
    <div class="app-modal-header">
        <h5 class="modal-title">Gebruiker</h5>
    </div>
    <div class="app-modal-body">
        <div class="card">
            <div class="card-body" *ngIf="user">
                <form autocomplete="off">
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="name">Naam</label>
                        <div class="col">
                            <input type="text" class="form-control" id="name" placeholder="Voornaam" name="name" [(ngModel)]="user.firstName">
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" id="famname" name="fanname" placeholder="Familienaam" [(ngModel)]="user.familyName">
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="abbrevTech">Afkorting technieker</label>
                        <div class="col">
                            <div class="input-group">
                                <input type="text" class="form-control" [readonly]="!isShortNameTechEdit" name="abbrevTech" id="abbrevTech" [(ngModel)]="user.shortNameTech">
                                <button class="btn" type="button" (click)="editShortNameTech()"><fa-icon icon="edit"></fa-icon></button>
                            </div>
                            <small class="form-hint">Dit wordt automatisch gegenereerd.</small>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="abbrevPlan">Afkorting planner/beheerder</label>
                        <div class="col">
                            <div class="input-group">
                                <input type="text" class="form-control" [readonly]="!isShortNamePlanEdit" name="abbrevPlan" id="abbrevPlan" [(ngModel)]="user.shortNamePlan">
                                <button class="btn" type="button" (click)="editShortNamePlan()"><fa-icon icon="edit"></fa-icon></button>
                            </div>
                            <small class="form-hint">Dit wordt automatisch gegenereerd.</small>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="email">Email</label>
                        <div class="col">
                            <input type="email" class="form-control" name="email" id="email" placeholder="Email" [(ngModel)]="user.email">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="form-label col-3 col-form-label pt-0">Rol</label>
                        <div class="col">
                            <label class="form-check">
                                <input class="form-check-input" type="checkbox" name="beheerder" [(ngModel)]="isAdminBool">
                                <span class="form-check-label">Beheerder</span>
                            </label>
                            <label class="form-check">
                                <input class="form-check-input" type="checkbox" name="technieker" [(ngModel)]="isTechnician">
                                <span class="form-check-label">Technieker</span>
                            </label>
                            <label class="form-check">
                                <input class="form-check-input" type="checkbox" name="planner" [(ngModel)]="isPlanner">
                                <span class="form-check-label">Planner</span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="form-label col-3 col-form-label pt-0">Status</label>
                        <div class="col">
                            <label class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="userStatusEmployed" [value]="userStatus.employed"
                                    [(ngModel)]="user.status">
                                <span class="form-check-label">In dienst</span>
                            </label>
                            <label class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="userStatusNotEmployed" [value]="userStatus.notEmployed"
                                    [(ngModel)]="user.status">
                                <span class="form-check-label">Uit dienst</span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="password">Nieuw wachtwoord</label>
                        <div class="col">
                            <input type="text" class="form-control" name="password" id="password" placeholder="Vul hier je nieuwe gewenste wachtwoord in" [(ngModel)]="password">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <div class="btn-list">
            <button type="button" class="btn btn-default me-1" (click)="myUserEditModal.hide();">
                Annuleren
            </button>
            <button *ngIf="!isNew" type="button" class="btn btn-primary" (click)="updateUser();">
                Opslaan
            </button>
            <button *ngIf="isNew" type="button" class="btn btn-primary" (click)="saveNewUser();">
                Opslaan
            </button>
        </div>
    </div>
</app-modal>