import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'
import { AddressMiniDto } from './AddressMiniDto';
import { ClientResidenceReferenceDto } from './ClientResidenceReferenceDto';
import { WorkOrderReferenceDto } from './WorkOrderReferenceDto';

export class ContractReferenceDto extends EntityReferenceDto {

    public type: enums.ContractType;
    public reference: string;
    public address: AddressMiniDto;
    public vatNumber: string;
    public startDate: Date;
    public endDate: Date;
    public workOrders: WorkOrderReferenceDto[];
    public residences: ClientResidenceReferenceDto[];

    constructor(init?: Partial<ContractReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}