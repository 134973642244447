import { Component, OnInit, ViewChild } from '@angular/core';
import { UserEditModalComponent } from '.';

import * as c from '../../core';
import * as m from '../../models';

@Component({
    templateUrl: './users-overview.html',
})
export class UsersOverviewComponent implements OnInit {
    users: m.UserAccountDto[];
    _listFilter: string;
    filteredUsers: m.UserAccountDto[];

    @ViewChild(UserEditModalComponent) userEdit: UserEditModalComponent;

    constructor(
        private dispatcher: c.Dispatcher
    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    async refresh() {
        let response = await this.dispatcher.send<m.GetUsersResponse>(new m.GetUsersRequest());
        this.users = response.users.sort(function (a: m.UserAccountDto, b: m.UserAccountDto) {
            if (a.familyName.toUpperCase() < b.familyName.toUpperCase()) {
                return -1;
            }
            if (a.familyName.toUpperCase() > b.familyName.toUpperCase()) {
                return 1;
            }
            return 0;
        });

        this.filteredUsers = response.users;
    }

    get listFilter(): string {
        return this._listFilter;
    }

    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredUsers = this.listFilter ? this.performFilter(this.listFilter) : this.users;
    }

    performFilter(filterBy: string): m.UserAccountDto[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.users.filter((user: m.UserAccountDto) => user.familyName.toLocaleLowerCase().indexOf(filterBy) !== -1 || user.firstName.toLocaleLowerCase().indexOf(filterBy) !== -1);
    }

    async deleteUser(id: number) {
        await this.dispatcher.send<m.DeleteUserResponse>(new m.DeleteUserRequest({ id: id }));
        await this.refresh();
    }

    async restoreUser(id: number) {
        await this.dispatcher.send<m.DeleteUserResponse>(new m.DeleteUserRequest({ id: id, restore: true }));
        await this.refresh();
    }

    sort(val: number) {
        if (val == 1) this.showTechnicians();
        else if (val == 2) this.showAdmins();
        else this.filteredUsers = this.users;
    }

    showAdmins() {
        this.filteredUsers = this.users.filter((user: m.UserAccountDto) => user.roles.indexOf(m.UserRole.administrator) != -1);
    }

    showTechnicians() {
        this.filteredUsers = this.users.filter((user: m.UserAccountDto) => user.roles.indexOf(m.UserRole.technician) != -1);
    }

    showPlanners() {
        this.filteredUsers = this.users.filter((user: m.UserAccountDto) => user.roles.indexOf(m.UserRole.planner) != -1);
    }

    openUserEditModal(user: m.UserAccountDto) {
        this.userEdit.user = JSON.parse(JSON.stringify(user));
        this.userEdit.show();
    }

    openUserCreateModal() {
        this.userEdit.showNew();
    }
}
