import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'
import { AddressMiniDto } from './AddressMiniDto';

export class ClientResidenceReferenceDto extends EntityReferenceDto {

    public key: string;
    public address: AddressMiniDto;
    public addressLine: string;
    public residentFirstName: string;
    public residentLastName: string;
    public residentPhone: string;
    public residentCellular: string;
    public contractId: number;
    public residentIsOwner: boolean;

    constructor(init?: Partial<ClientResidenceReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}