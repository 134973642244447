import { Component, Input, AfterViewInit, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

const ASP_CUSTOM_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
declare var $: any;

@Component({
    selector: 'app-datetime',
    template: `
<div class="input-group datetime">
    <input type="text" class="form-control" [disabled]="_isDisabled">
    <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" [disabled]="_isDisabled" (click)="toggle();">
            <fa-icon icon="calendar-alt"></fa-icon>
        </button>
    </div>
</div>
`,
    styles: [`
.datetime {
    width: 150px;
}
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DatetimeComponent),
        }
    ]
})
export class DatetimeComponent implements AfterViewInit, ControlValueAccessor {
    public innerValue: string;
    public picker: any;
    public format: string = 'dd/mm/yyyy';
    public minViewMode: number = 0;

    public _isDisabled: boolean = false;
    public _onChange: (_: any) => void;
    public _onTouched: any;
    public _shown: boolean = false;

    @Input() set minView(newValue: number) {
        this.minViewMode = newValue;
    }
    @Input() set dateFormat(newValue: string) {
        this.format = newValue;
    }

    writeValue(obj: any): void {
        if (!obj) return;

        this.innerValue = obj;
        this.reviewDatepickerDate();
    }
    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }
    toggle() {
        if (this._shown)
            this.picker.datepicker('hide');
        else
            this.picker.datepicker('show');

        this._shown = !this._shown;
    }

    constructor(
        private el: ElementRef
    ) { }

    ngAfterViewInit() {
        this.picker = $('.datetime input', this.el.nativeElement)
            .datepicker({
                weekStart: 1,
                format: this.format,
                minViewMode: this.minViewMode,
                orientation: 'bottom'
            });

        this.picker.on('changeDate', (e: any) => {
            this.innerValue = moment(e.date).format(ASP_CUSTOM_ISO_FORMAT);
            this._onChange(this.innerValue);
        });

        this.picker.on('show', () => { this._shown = true; });
        this.picker.on('hide', () => { this._shown = false; });

        this.reviewDatepickerDate();
    }

    private reviewDatepickerDate() {
        if (!this.picker)
            return;

        let pickerDate = this.picker.datepicker('getDate');
        let pickerDateAsString = moment(pickerDate).format(ASP_CUSTOM_ISO_FORMAT);

        if ((this.innerValue == null && pickerDate == null)
            || this.innerValue == pickerDateAsString)
            return;

        let date = moment(this.innerValue, moment.ISO_8601).toDate();
        this.picker.datepicker('setDate', date);
    }
}