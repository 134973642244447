import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { finalize, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { FileInfo } from './file-info';

@Component({
    selector: 'file-upload',
    template: `
    <button type="button" class="btn btn-success upload" [disabled]="disabled || _uploading">
        <i *ngIf="!_uploading"></i>
        <i *ngIf="_uploading"></i>
        &nbsp;Upload File
        <input #myInput type="file" (change)="fileChange($event)">
    </button>
`,
    styles: [`
    .upload {
        position: relative;
        overflow: hidden;
    }
    .upload input[type=file] { 
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        font-size: 100px;
        filter: alpha(opacity=0);
        cursor: pointer;
    }`]
})

export class FileUpload {
    _uploading: boolean = false;

    @Input() targetUrl = 'upload-file';

    @Input() disabled: boolean = false;

    @ViewChild('myInput')
    myInputVariable: ElementRef;

    @Output() fileUploaded = new EventEmitter<FileInfo>();

    constructor(
        private HttpClient: HttpClient
    ) {
    }

    fileChange(event: any) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            const formData: FormData = new FormData();

            formData.append('uploadFile', file, file.name);

            const headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');;
            const options = { headers: headers };

            this._uploading = true;

            this.HttpClient
                .post(this.targetUrl, formData, options)
                .pipe(
                    catchError(error => error),
                    finalize(() => this._uploading = false)
                )
                .subscribe(
                    (response: FileInfo[]) => {
                        response.forEach((info) => { this.fileUploaded.emit(info) });
                        this.reset();
                    }
                );
        }
    }

    private reset() {
        this.myInputVariable.nativeElement.value = '';
        this.fileUploaded.emit(null);
    }
}