import * as enums from '../enums';
import { EntityDto } from './EntityDto'
import { ResidentReferenceDto } from './ResidentReferenceDto';
import { ClientResidenceReferenceDto } from './ClientResidenceReferenceDto';

export class ClientResidenceHistoryDto extends EntityDto {

    public resident: ResidentReferenceDto;
    public clientResidence: ClientResidenceReferenceDto;
    public remark: string;
    public date: Date;

    constructor(init?: Partial<ClientResidenceHistoryDto>) {
        super(init);
        Object.assign(this, init);
    }
}