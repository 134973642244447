import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class CreateResidenceRequest implements IRequest<CreateResidenceResponse> {
    constructor(init?: Partial<CreateResidenceRequest>) {
        Object.assign(this, init);
    }

    address: dtos.AddressMiniDto;
    resident: dtos.ResidentReferenceDto;
    contractId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.ClientResidences.CreateResidenceRequest';
}

export class CreateResidenceResponse extends ImversaResponse {
    residence: dtos.ClientResidenceDto;

    constructor(init?: Partial<CreateResidenceResponse>) {
        super();
        Object.assign(this, init);
    }
} 