<div class="page-wrapper">
    <div class="container-xl">
        <div class="page-header d-print-none">
            <div class="row align-items-center">
                <div class="col">
                    <h1 class=" d-flex justify-content-center">
                        Gebruikers
                    </h1>
                    <div class="page-pretitle d-flex justify-content-center">
                        Beheerscherm voor al de gebruikers die toegang hebben tot de applicatie.
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-9">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="page-title">
                                Overzicht
                            </h3>
                        </div>
                        <div class="col-auto ms-auto">
                            <div class="btn-list">
                                <button type="button" class="btn btn-primary" (click)="openUserCreateModal()">
                                    Toevoegen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-2" *ngFor="let user of filteredUsers;">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <span class="w-50">
                                <a href="javascript:void(0);" class="fw-bold" (click)="openUserEditModal(user)">
                                    {{user.firstName}} {{user.familyName}} - {{user.shortNameTech}} {{user.shortNamePlan}}
                                </a>
                                &nbsp;&nbsp;
                                <a href="javascript:void(0);" *ngIf="user.isActive" (click)="deleteUser(user.id)">
                                    <fa-icon icon="trash"></fa-icon>
                                </a>
                                <a href="javascript:void(0);" *ngIf="!user.isActive" (click)="restoreUser(user.id)">
                                    <fa-icon icon="trash-restore"></fa-icon>
                                </a>
                            </span>
                            <span>
                                {{user.email}} <span class="badge bg-danger" *ngIf="!user.isActive">Inactief</span>
                            </span>
                        </div>
                        <span *ngFor="let r of user.roles; let isLast=last">
                            {{ r | enum | translate }}{{!isLast ? '&nbsp;/&nbsp;' : ''}}
                        </span>
                    </div>
                </div>

                <user-edit #userEdit (onChange)="refresh()"></user-edit>
            </div>

            <aside class="col-3 flex-column align-items-stretch flex-shrink-0">
                <div class="card mt-2">
                    <div class="card-body">
                        <div class="border-bottom border-2 mb-2">
                            <div class="input-group">
                                <span class="input-group-text">
                                    <fa-icon icon="search"></fa-icon>
                                </span>
                                <input type="text" class="form-control" placeholder="Voornaam of familienaam" autocomplete="off" [(ngModel)]="listFilter">
                            </div>
                        </div>
                        <div class="w-100">
                            <label class="form-label">Rol</label>
                            <select class="form-select" (change)="sort($event.target.value);">
                                <option value=""></option>
                                <option value="1">Technieker</option>
                                <option value="2">Beheerder</option>
                                <option value="3">Planner</option>
                            </select>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>