<div class="m-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="m-portlet m-portlet--responsive-mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text m--font-success">
                                Afwezigheden
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <div class="btn-group" role="group">
                                <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text m--font-success" id="btnGroupAddon">Filter</span></div>
                                        <input type="text" class="form-control" placeholder="Familienaam" aria-describedby="btnGroupAddon" [(ngModel)]="listFilter">
                                    </div>
                            <button id="btnGroupDrop1" type="button" class="m-btn btn btn-brand m--font-success dropdown-toggle"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <fa-icon icon="sort"></fa-icon>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <a class="dropdown-item" (click)="refresh()">Overview </a>
                                <a class="dropdown-item" (click)="filterPastAbsences()">Afwezigheden in verleden</a>
                                <a class="dropdown-item" (click)="filterFutureAbsences()">Afwezigheden in toekomst</a>
                                <a class="dropdown-item">Type</a>
                                <div class="dropdown-item" (click)="showZiekte()">
                                    <a class="dropdown-item">Ziekte</a>
                                </div>
                                <div class="dropdown-item" (click)="showVakantie()">
                                    <a class="dropdown-item">Vakantie</a>
                                </div>
                                <div class="dropdown-item" (click)="showOveruren()">
                                    <a class="dropdown-item">Overuren</a>
                                </div>
                            </div>
                            <button type="button" class="m-btn btn btn-brand m--font-success" [routerLink]="['/absences/', getSelectedAbsenceId(), 'edit']"
                                [disabled]="isEditDisabled()">
                                <fa-icon icon="edit"></fa-icon>
                            </button>
                            <button type="button" class="m-btn btn btn-brand m--font-success" [routerLink]="['/absences/', 'create']">
                                <fa-icon icon="plus-square"></fa-icon>
                            </button>
                            <button type="button" class="m-btn btn btn-danger" (click)="deleteAbsence()"
                                [disabled]="isRemoveDisabled()">
                                <fa-icon icon="trash-alt"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>

            <ul class="list-group">
                <li class="list-group-item list-group-item-action flex-column" 
                *ngFor="let absence of filteredAbsences; index as i"
                    [attr.data-index]="i" 
                    (click)="toggleAbsenceField(absence); highlightAbsences[i]=!highlightAbsences[i]"
                    [class.active]="highlightAbsences[i]">

                    <div class="d-flex justify-content-between">
                        <label class="w-25" for="clickedAbsence{{i}}">
                            <a [routerLink]="['/absences/', absence.id, 'edit']">
                                {{absence.userAccountFirstName}} {{absence.userAccountFamilyName}}</a></label>
                        <p class="w-25">{{absence.startTime | date: 'dd/MM/yyyy'}} > {{absence.endTime | date:
                            'dd/MM/yyyy' }}</p>
                        <div>
                            {{absence.type | enum | translate}}
                        </div>
                    </div>
                    <small class="text-muted align-self-start">{{absence.remark}}</small>
                </li>
            </ul>
        </div>
    </div>
</div>
</div>