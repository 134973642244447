import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'propertyFilter'
})
@Injectable()
export class PropertyFilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string): any[] {
        if (!items) return items;
        return items.filter(it => it[field] === value);
    }
}