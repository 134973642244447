<div class="m-content" *ngIf="contract">
    <div class="row">
        <div class="col-lg-12">
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Contract: {{contract.reference}}
                            </h3>
                        </div>
                    </div>
                </div>
                <form #form="ngForm" class="m-form m-form--label-align-right ng-untouched ng-pristine ng-valid"
                    novalidate>
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">

                            <div class="m-list-search">
                                <div class="m-list-search__results">
                                    <span class="m-list-search__result-category m-list-search__result-category--first">
                                        Gegevens
                                    </span>
                                    <div class="form-group m-form__group row">
                                        <label for="referentie" class="col-lg-3 col-form-label">Referentie:</label>
                                        <div class="col-lg-6">
                                            <input class="form-control m-input" type="text" name="referentie"
                                                [(ngModel)]="contract.reference">
                                        </div>
                                    </div>
                                    <div class="m-form__group form-group row">
                                        <label for="contractType" class="col-3 col-form-label">Type:</label>
                                        <div class="m-radio-inline">
                                            <label class="m-radio" for="inlineRadio1">
                                                <input type="radio" name="vakantie" id="inlineRadio1"
                                                    [(ngModel)]="contract.type" [value]="type.company"> Bedrijf
                                                <span></span>
                                            </label>
                                            <label class="m-radio" for="inlineRadio2">
                                                <input type="radio" name="ziekte" id="inlineRadio2"
                                                    [(ngModel)]="contract.type" [value]="type.private"> Particulier
                                                <span></span>
                                            </label>
                                            <label class="m-radio" for="inlineRadio3">
                                                <input type="radio" name="ziekte" id="inlineRadio3"
                                                    [(ngModel)]="contract.type" [value]="type.tender"> Aanbesteding
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="m-form__seperator m-form__seperator--dashed"></div>

                            <div class="m-form__section m-form__section">
                                <div class="m-list-search">
                                    <div class="m-list-search__results">
                                        <span
                                            class="m-list-search__result-category m-list-search__result-category--first">
                                            Residenties
                                        </span>
                                        <div *ngFor="let cr of contract.residences">
                                            <clientresidence-overview [residence]="cr"></clientresidence-overview>
                                        </div>

                                        <button class="btn btn-default" (click)="addResidence()">Nieuw adres</button>
                                        <button class="btn btn-default" (click)="addResidenceList()">Nieuwe
                                            excel</button>

                                        <app-modal #myModal>
                                            <div class="app-modal-header">
                                                <h5 class="modal-title">Nieuwe residentie</h5>
                                            </div>
                                            <div class="app-modal-body">
                                                <clientresidence-new #crDetail [(cr)]="crNew" [contractId]="contractId">
                                                </clientresidence-new>
                                            </div>
                                            <div class="app-modal-footer">
                                                <button type="button" class="btn btn-default"
                                                    (click)="myModal.hide()">Terug</button>
                                                <button type="button" class="btn btn-primary"
                                                    (click)="handleSaveIsNewResidence();myModal.hide()">
                                                    Opslaan
                                                </button>
                                            </div>
                                        </app-modal>

                                        <app-modal #myModalUpload>
                                            <div class="app-modal-header">
                                                <h5 class="modal-title">Ingave excel</h5>
                                            </div>
                                            <div class="app-modal-body">
                                                <form method="post">
                                                    <div class="m-portlet__body">
                                                        <div class="m-form__section m-form__section--first">
                                                            <div class="form-group m-form__group d-flex flex-column">
                                                                <file-upload (fileUploaded)="onFileUploaded($event)">
                                                                </file-upload>

                                                                <div class="highlight" *ngIf="uploadedFile">
                                                                    Dit bestand {{uploadedFile.fileName}} is
                                                                    geselecteerd. Druk op opslaan om dit op te laden of
                                                                    op terug om terug te gaan naar de
                                                                    contractdetail-pagina.
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="app-modal-footer">
                                                <button type="button" class="btn btn-default"
                                                    (click)="hideMyModalUpload()">Terug</button>
                                                <button type="button" class="btn btn-primary"
                                                    (click)="handleSaveNewExcelData();hideMyModalUpload()">
                                                    Opslaan
                                                </button>
                                            </div>
                                        </app-modal>
                                        <app-modal #myModalUploadBadList>
                                            <div class="app-modal-header">
                                                <h5 class="modal-title">Fout bij het opladen</h5>
                                            </div>
                                            <div class="app-modal-body">
                                                <form method="post">
                                                    <div class="m-portlet__body">
                                                        <div class="m-form__section m-form__section--first">
                                                            <div class="form-group m-form__group row">
                                                                <div class="highlight">
                                                                    De volgende postcodes en steden zijn niet gekend in
                                                                    de databank:
                                                                    <table *ngFor="let c of correctCities">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{{c.postalCode}}</td>
                                                                                <td>{{c.incorrectCityName}}</td>
                                                                                <td>Alternatieven:
                                                                                    <span
                                                                                        *ngFor="let name of c.alternativeNames; last as isLast">
                                                                                        {{name |titlecase}}
                                                                                        <span *ngIf="!isLast">, </span>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="app-modal-footer">
                                                <button type="button" class="btn btn-default" (click)="myModalUploadBadList.hide()">Ok</button>
                                            </div>
                                        </app-modal>
                                    </div>
                                </div>
                            </div>
                            <div class="m-form__section m-form__section--last">
                                <div class="m-list-search">
                                    <div class="m-list-search__results">
                                        <span
                                            class="m-list-search__result-category m-list-search__result-category--first">
                                            Taken
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
                <btn-save class="mt-3" [onSave]="handleSaveIsNotNew"></btn-save>
                <button type="button" class="btn btn-default" (click)="returnToOverview()">Terug</button>
            </div>
        </div>
    </div>
</div>