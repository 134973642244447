import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fas from '@fortawesome/free-solid-svg-icons';

library.add(far.far);
library.add(fas.fas);

import * as absences from './absences';
import * as users from './users';
import * as sandbox from './sandbox';
import * as residences from './clientresidence';
import * as contracts from './contracts';
import * as residents from './residents';
import * as addresses from './addresses';
import * as wo from './work-order';

import { CoreModule } from "../core";
import { MainComponent } from "./main.component";
import { HeaderComponent } from "./header.component";

import * as routes from './main.routes';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        NgSelectModule,
        CoreModule,
        RouterModule,
        FontAwesomeModule,
        routes.routing
    ],
    declarations: [
        MainComponent,
        HeaderComponent,
        
        absences.AbsencedetailComponent,
        absences.AbsencesOverviewComponent,

        addresses.AddressdetailComponent,

        contracts.ContractDetailComponent,
        contracts.ContractoverviewComponent,
        contracts.ContractnewComponent,

        users.UsersOverviewComponent,
        users.UserEditModalComponent,

        residents.ResidentdetailComponent,
        residents.ResidentsoverviewComponent,

        sandbox.SandboxComponent,

        residences.ClientresidenceoverviewComponent,
        residences.ClientresidenceDetailComponent,
        residences.ClientresidenceNewComponent,

        wo.ImportComponent,
        wo.WorkOrderComponent,
        wo.OverviewComponent,
        wo.WorkOrderModalComponent,
        wo.AddressFormComponent,
        wo.TechnicianOverviewComponent,
        wo.WorkOrderModalTechnicianComponent
    ],
    providers: [
    ],
    exports: [
        RouterModule,
    ]
})
export class MainModule {
}