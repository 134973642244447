<ul class="pagination m-0 ml-auto" *ngIf="pages">
    <li class="page-item" [class.disabled]="disablePrev">
        <a href="javascript:void(0)" class="page-link" (click)="prev();" tabindex="-1">prev</a>
    </li>
    <li class="page-item" [class.active]="currentPage === 1" *ngIf="enableFirst">
        <a href="javascript:void(0)" class="page-link" (click)="setCurrentPage(1);">1</a>
    </li>
    <li *ngIf="enableFirstDots">
        <a class="page-link">...</a>
    </li>
    <li class="page-item" [class.active]="currentPage === page" *ngFor="let page of pages;">
        <a href="javascript:void(0)" class="page-link" (click)="setCurrentPage(page);">{{page}}</a>
    </li>
    <li *ngIf="enableLastDots">
        <a class="page-link">...</a>
    </li>
    <li class="page-item" [class.active]="currentPage === lastPage" *ngIf="enableLast">
        <a href="javascript:void(0)" class="page-link" (click)="setCurrentPage(lastPage);">{{lastPage}}</a>
    </li>
    <li class="page-item" [class.disabled]="disableNext">
        <a href="javascript:void(0)" class="page-link" (click)="next();">next</a>
    </li>
</ul>