import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'file-generate',
    template: `
    <button type="button" class="btn btn-success" (click)="generate()" [disabled]=loading>
        <i *ngIf="loading; else noLoading">
            <fa-icon icon="fa-sync" class="mr-1"></fa-icon>
        </i>
        <ng-template #noLoading>
            <i>
            <fa-icon icon="cloud-upload-alt" class="mr-1"></fa-icon>
            </i>
        </ng-template>
        &nbsp;{{ title }}
    </button>
`
// | translate

})
export class FileGenerate {
    loading: boolean = false;

    @Input() title: string = 'client.export';

    public generate() {
        this.loading = true;
        const result: Observable<any> = this.onGenerate();
        result.pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((key: string) => {
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                let href = document.querySelector('base').getAttribute('href');
                iframe.src = href + "file-download?key=" + key;

                document.body.appendChild(iframe);
            });
    }

    onGenerate: any = null;
}