<div *ngIf="user" class="container-fluid">
    <h3>Wachtwoord wijzigen voor {{user.firstName}} {{user.familyName}}</h3>
    <form #form="ngForm" class="d-flex flex-column" novalidate>
        <div class="form-group d-flex justify-content-start">
            <label for="newPassword" class="col-form-label w-25">Nieuw wachtwoord: </label>
            <input id="newPassword" type="password" class="form-control w-25" name="newpassword" [(ngModel)]="nieuwPaswoord">
        </div>
        <div class="form-group d-flex justify-content-start">
            <label for="confirmPassword" class="col-form-label w-25"> Wachtwoord bevestigen:</label>
            <input id="confirmPassword" type="password" class="form-control w-25" name="confirmpassword" [(ngModel)]="bevestigPaswoord">
        </div>
    </form>

    <button type="button" class="btn btn-primary" (click)="saveNewPassword()">
        <span class="glyphicon glyphicon-ok"> Opslaan </span>
    </button>
    <button type="button" class="btn btn-primary" >
        <span class="glyphicon glyphicon-ok"  [routerLink]="['/users/', user.id]"> Annuleer </span>
    </button>
</div>