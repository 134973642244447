<div class="m-content" *ngIf="resident">
    <div class="row">
        <div class="col-lg-12">
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                {{resident.firstName}} {{resident.lastName}}
                            </h3>
                        </div>
                    </div>
                </div>
                <!--begin::Form-->
                <form class="m-form m-form--label-align-right ng-untouched ng-pristine ng-valid">
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">
                            <div class="m-form__heading">
                                <h3 class="m-form__heading-title">Persoonsgegevens:</h3>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="Name" class="col-lg-3 col-form-label"> Voornaam: </label>
                                <div class="col-lg-6">
                                    <input id="Name" type="text" class="form-control m-input" placeholder="Voornaam"
                                        name="firstname" [(ngModel)]="resident.firstName" />
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="E-mail" class="col-lg-3 col-form-label"> Familienaam: </label>
                                <div class="col-lg-6">
                                    <input id="familyName" type="text" class="form-control m-input" placeholder="Familienaam"
                                        name="familyname" [(ngModel)]="resident.lastName" />
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="E-mail" class="col-lg-3 col-form-label"> E-mail: </label>
                                <div class="col-lg-6">
                                    <input id="E-mail" type="email" class="form-control m-input" placeholder="E-mail"
                                        name="email" [(ngModel)]="resident.email">
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="telephoneNumber" class="col-lg-3 col-form-label"> Telefoonnummer: </label>
                                <div class="col-lg-6">
                                    <input id="telephoneNumber" type="text" class="form-control m-input" placeholder="04..."
                                        name="Telefoonnummer" [(ngModel)]="resident.phone">
                                </div>
                            </div>
                        </div>

                        <div class="m-form__seperator m-form__seperator--dashed"></div>

                        <div class="m-form__section m-form__section--last">
                            <div class="m-form__heading">
                                <h3 class="m-form__heading-title">Adres:</h3>
                            </div>
                            <address-detail [object]="resident"></address-detail>
                        </div>
                    </div>
                    <div class="m-portlet__foot m-portlet__foot--fit">
                        <div class="m-form__actions m-form__actions">
                            <div class="row">
                                <div class="col-lg-3"></div>
                                <div class="col-lg-6">
                                    <btn-save *ngIf="isNew" [onSave]="handleSaveIsNewResident"></btn-save>
                                    <btn-save *ngIf="!isNew" [onSave]="handleSaveIsExistingResident"></btn-save>
                                    <button type="button" class="btn btn-default" (click)="returnToOverview()">Terug</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <!--end::Form-->
            </div>
        </div>
    </div>
</div>