import { Component, Input, Output, ViewChild, EventEmitter } from "@angular/core";

import * as c from '../../core';
import * as m from '../../models';

@Component({
    selector: 'user-edit',
    templateUrl: 'user-edit-modal.html'
})
export class UserEditModalComponent {
    public isAdminBool: boolean = false;
    public isTechnician: boolean = false;
    public isPlanner: boolean = false;
    public isNew: boolean = false;
    public isShortNameTechEdit: boolean = false;
    public isShortNamePlanEdit: boolean = false;
    public password: string;
    public rol = m.UserRole;
    public userStatus = m.UserStatus;

    @Input() public user: m.UserAccountDto;

    @ViewChild('myUserEditModal')
    myUserEditModal: c.ModalComponent;

    @Output() onChange = new EventEmitter();

    constructor(
        private dispatcher: c.Dispatcher
    ) { }

    show() {
        this.resetBools();
        this.isNew = false;
        this.password = '';

        this.user.roles.forEach(x => {
            if (x == 3001) { this.isAdminBool = true }
            if (x == 3002) { this.isTechnician = true }
            if (x == 3003) { this.isPlanner = true }
        });
        this.myUserEditModal.show();
    }

    showNew() {
        this.resetBools();
        this.isNew = true;
        this.password = '';

        this.user = new m.UserAccountDto({
            firstName: '',
            familyName: '',
            shortNameTech: '',
            shortNamePlan: '',
            email: '',
            status: m.UserStatus.employed,
            roles: []
        });

        this.myUserEditModal.show();
    }

    isAdmin(user: m.UserAccountDto): boolean {
        return (user.roles != null && user.roles.indexOf(3001) != -1);
    }

    async updateUser() {
        const request = new m.UpdateUserRequest({
            id: this.user.id,
            familyName: this.user.familyName,
            firstName: this.user.firstName,
            email: this.user.email,
            status: this.user.status,
            shortNamePlan: this.user.shortNamePlan,
            shortNameTech: this.user.shortNameTech,
            roles: [],
            password: this.password
        })

        if (this.isAdminBool) {
            request.roles.push(m.UserRole.administrator);
        }
        if (this.isTechnician) {
            request.roles.push(m.UserRole.technician);
        }
        if (this.isPlanner) {
            request.roles.push(m.UserRole.planner);
        }

        try {
            await this.dispatcher.send<m.UpdateUserResponse>(request);
            this.onChange.emit(null);
            this.myUserEditModal.hide();
        } catch {
            // On Exception
        }

        this.isShortNameTechEdit = false;
        this.isShortNamePlanEdit = false;
    }

    resetBools() {
        this.isAdminBool = false;
        this.isTechnician = false;
        this.isPlanner = false;
        this.isNew = false;
        this.isShortNameTechEdit = false;
        this.isShortNamePlanEdit = false;
    }

    editShortNameTech(){
        this.isShortNameTechEdit = true;
    }

    editShortNamePlan(){
        this.isShortNamePlanEdit = true;
    }

    async saveNewUser() {
        const request = new m.CreateUserRequest({
            firstName: this.user.firstName,
            familyName: this.user.familyName,
            email: this.user.email,
            status: this.user.status,
            shortNamePlan: this.user.shortNamePlan,
            shortNameTech: this.user.shortNameTech,
            roles: [],
            password: this.password
        })

        if (this.isAdminBool) {
            request.roles.push(m.UserRole.administrator);
        }
        if (this.isTechnician) {
            request.roles.push(m.UserRole.technician);
        }
        if (this.isPlanner) {
            request.roles.push(m.UserRole.planner);
        }

        try {
            await this.dispatcher.send<m.CreateUserResponse>(request);
            this.onChange.emit(null);
            this.myUserEditModal.hide();
        } catch {
            // On Exception
        }
    }
}