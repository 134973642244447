import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'

export class WorkOrderTechnicianEditReferenceDto extends EntityReferenceDto {

    public workOrderId: number;
    public userAccountId: number;
    public performedWork: string;
    public deliveredMaterial: string;
    public installationData: string;
    public furtherActionNeeded: boolean;
    public furtherActionDescription: string;

    constructor(init?: Partial<WorkOrderTechnicianEditReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}