import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class ServerExceptionRequest implements IRequest<ServerExceptionResponse> {
    constructor(init?: Partial<ServerExceptionRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.System.ServerExceptionRequest';
}

export class ServerExceptionResponse extends ImversaResponse {

    constructor(init?: Partial<ServerExceptionResponse>) {
        super();
        Object.assign(this, init);
    }
} 