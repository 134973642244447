import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class OpenNewRecordRequest implements IRequest<OpenNewRecordResponse> {
    constructor(init?: Partial<OpenNewRecordRequest>) {
        Object.assign(this, init);
    }

    contractId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.Tasks.OpenNewRecordRequest';
}

export class OpenNewRecordResponse extends ImversaResponse {
    workOrderLabels: dtos.WorkOrderLabelReferenceDto[];
    technicians: dtos.UserAccountReferenceDto[];
    requesters: dtos.UserAccountReferenceDto[];
    residences: dtos.ClientResidenceReferenceDto[];

    constructor(init?: Partial<OpenNewRecordResponse>) {
        super();
        Object.assign(this, init);
    }
} 