import { Component, Input, ViewChild, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ISortnode } from './sortnode';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'sort-modal',
    templateUrl: './sort-modal.html',
    styles: [ `.sort-button {
                width: 80%; 
                text-align: left; 
            }` ]
})
export class SortModalComponent implements OnChanges {
    @Input() nodes: ISortnode[];
    @Input() changeParent: boolean = false;
    @Output() save: EventEmitter<ISortnode[]> = new EventEmitter();

    internalNodes: ISortnode[];
    @ViewChild('sordermodal') modal: ModalComponent;

    selectedNode: ISortnode = null;

    show(nodes: ISortnode[]) {
        this.nodes = nodes;
        this.refreshInternalNodes();

        this.modal.show();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["nodes"]) {
            this.refreshInternalNodes();
        }
    }

    internalSave() {
        this.save.emit(this.internalNodes);
        this.modal.hide();
    }

    private refreshInternalNodes() {
        this.selectedNode = null;
        this.internalNodes = this.nodes.map(x => Object.assign({}, x));
        this.sortNodes();
    }

    spacing(node : ISortnode): string {
        let spacing = "";

        for (let i = 0; i < +node["depth"]; i++) {
            spacing += "&nbsp;&nbsp;";
        }

        return spacing;
    }

    private sortNodes() : void {
        this.internalNodes = this.internalNodes.sort((n1, n2) => n1.order - n2.order);
        this.internalNodes = this.sortNodesRecursive(null, this.internalNodes, 0);
    }

    private sortNodesRecursive(parentId: number, nodes: ISortnode[], depth: number): ISortnode[] {
        let returnValue = new Array<ISortnode>();

        for (let node of nodes) {
            if (node.parentId == parentId) {
                node["depth"] = depth;
                returnValue.push(node);
                returnValue = returnValue.concat(this.sortNodesRecursive(node.id, nodes, depth + 1));
            }
        }

        return returnValue;
    }

    public moveUp(): void {
        let selectedIdx = this.internalNodes.findIndex(x => x.id === this.selectedNode.id);

        let prevIdx = selectedIdx - 1;
        var selected = this.internalNodes[selectedIdx];
        if(selected.id == -1 && selected.parentId == undefined && selected["depth"] == 0){
            return;
        }
        if(this.internalNodes[prevIdx]["depth"] < selected["depth"] && this.changeParent === true){

            var parentOld = this.internalNodes[prevIdx];
            var parentNew = this.internalNodes.find(x => x["depth"] === parentOld["depth"] && x.parentId === parentOld.parentId && x.order === parentOld.order - 1)
            if(parentNew !== undefined){
                var count = this.internalNodes.filter(x => x.parentId == parentNew.id &&  x["depth"] === this.internalNodes[selectedIdx]["depth"]).length;
                this.internalNodes[selectedIdx].parentId = parentNew.id
                this.internalNodes[selectedIdx].order = count;
                this.sortNodes();
            }
        }
        else{
            while (prevIdx >= 0 && this.internalNodes[prevIdx].parentId !== this.internalNodes[selectedIdx].parentId)
                prevIdx--;

            if (prevIdx >= 0) {
                let originalOrder = this.internalNodes[selectedIdx].order;
                this.internalNodes[selectedIdx].order = this.internalNodes[prevIdx].order;
                this.internalNodes[prevIdx].order = originalOrder;

                this.sortNodes();
            }
        }

    }

    public moveDown(): void {
        let selectedIdx = this.internalNodes.findIndex(x => x.id === this.selectedNode.id);

        let nextIdx = selectedIdx + 1;
        var parentNew = this.internalNodes[nextIdx];
        if(parentNew == undefined || parentNew == null){
            return;
        }
        var selected = this.internalNodes[selectedIdx]
        if(selected.id == -1 && selected.parentId == undefined && selected["depth"] == 0){
            return;
        }
        if(parentNew["depth"] < selected["depth"] && this.changeParent === true){

            if(parentNew !== undefined){
                this.internalNodes.forEach(x =>{
                    if( x.parentId === parentNew.id &&  x["depth"] === selected["depth"]){
                        x.order++;
                    }
                });

                this.internalNodes[selectedIdx].parentId = parentNew.id
                this.internalNodes[selectedIdx].order = 0;
                this.sortNodes();
            }
        }else{
            if(parentNew.id == -1){
                return;
            }
            while (nextIdx < this.internalNodes.length && this.internalNodes[nextIdx].parentId !== this.internalNodes[selectedIdx].parentId)
                nextIdx++;

            if (nextIdx < this.internalNodes.length) {
                let originalOrder = this.internalNodes[selectedIdx].order;
                this.internalNodes[selectedIdx].order = this.internalNodes[nextIdx].order;
                this.internalNodes[nextIdx].order = originalOrder;

                this.sortNodes();
            }
        }
    }
}