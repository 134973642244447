import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import localeNl from '@angular/common/locales/nl';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { CoreModule, LogOutInterceptor } from './core';
import { MainModule } from './main';
import { LoginModule } from './login';

import { AppComponent } from './app.component';
import { ExceptionHandler } from './core/exceptions';

import * as appRoutes from './app.routes';

registerLocaleData(localeNl, 'nl');

@NgModule({
    imports: [
        CoreModule,
        BrowserModule,
        HttpClientModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,

        MainModule,
        LoginModule,

        appRoutes.routing,
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        { provide: ErrorHandler, useClass: ExceptionHandler },
        { provide: HTTP_INTERCEPTORS, useClass: LogOutInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'nl-BE' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
    }
}
