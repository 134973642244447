<div class="form-group mb-3 row" *ngIf="address">
    <label class="form-label col-3 col-form-label" for="street">Straat</label>
    <div class="col">
        <input type="text" class="form-control" name="street" id="street" [(ngModel)]="address.street">
    </div>
</div>
<div class="form-group mb-3 row d-flex align-items-center" *ngIf="address">
    <label class="form-label col-3 col-form-label d-flex align-items-center" for="streetNumber">Nummer</label>
    <div class="col d-flex align-items-center">
        <input type="text" class="form-control d-flex align-items-center" id="streetNumber" name="streetNumber" [(ngModel)]="address.number">
    </div>
    <div class="col d-flex align-items-center" *ngIf="address">
        <label class="form-label col-3 col-form-label d-flex align-items-center" for="streetBus">Bus</label>
        <input type="text" class="form-control d-flex align-items-center" id="streetBus" name="streetBus" [(ngModel)]="address.bus">
    </div>
</div>
<div class="form-group mb-3 row" *ngIf="address">
    <label class="form-label col-3 col-form-label" for="city">Postcode &amp; stad</label>
    <div class="col">
        <select class="form-select" (change)="onChangeCity($event.target.value)" name="city">
            <option>{{address.cityPostalCode}} {{address.cityName}}</option>
            <option *ngFor="let c of cities" [value]="c.id">{{c.postalCode}} {{c.name}}</option>
        </select>
    </div>
</div>