<app-modal #myWorkOrderModal>
    <div class="app-modal-header">
        <h5 class="modal-title">Werkbon</h5>
    </div>
    <div class="app-modal-body">
        <div class="card mb-3">
            <div class="card-body" *ngIf="workOrder">
                <form>
                    <div class="form-group mb-3 row d-flex align-items-center">
                        <label class="form-label col-3 col-form-label d-flex align-items-center" for="name">Naam
                            klant</label>
                        <div class="col d-flex align-items-center">
                            <input type="text" class="form-control d-flex align-items-center" id="name"
                                placeholder="Voornaam" name="name"
                                [(ngModel)]="workOrder.clientResidenceResidentFirstName">
                        </div>
                        <div class="col d-flex align-items-center">
                            <label class="form-label col-form-label d-flex align-items-center" for="lastName"></label>
                            <input type="text" class="form-control d-flex align-items-center" id="lastName"
                                name="lastName" placeholder="Familienaam"
                                [(ngModel)]="workOrder.clientResidenceResidentLastName">
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="telephoneNumber">Telefoonnummer</label>
                        <div class="col">
                            <input type="text" class="form-control" name="telephoneNumber" id="telephoneNumber"
                                [(ngModel)]="workOrder.clientResidenceResidentPhone">
                        </div>
                    </div>

                    <div class="form-group mb-3 row" *ngIf="!showAddressForm">
                        <label class="form-label col-3 col-form-label" for="adres">Adres</label>
                        <div class="col input-group">
                            <input type="text" class="form-control" name="adres" id="adres" [value]="workOrder.clientResidenceAddressLine" disabled readonly>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" (click)="editAddress();">
                                    <fa-icon icon="edit"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <address-form *ngIf="showAddressForm" [(address)]="editedAddress"></address-form>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="workorderDate">Datum</label>
                        <div class="col">
                            <app-datetime name="workorderDate" id="workorderDate" [(ngModel)]="workOrder.date">
                            </app-datetime>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="description">Omschrijving</label>
                        <div class="col">
                            <input type="text" class="form-control" name="description" id="description"
                                [(ngModel)]="workOrder.description">
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="requester">Afgesproken met</label>
                        <div class="col">
                            <select class="form-select" [(ngModel)]="workOrder.requesterId" name="requester">
                                <option value=""></option>
                                <option *ngFor="let r of requesters" [value]="r.id">{{r.text}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="technician">Uitvoerders</label>
                        <div class="col">
                            <div class="list-group" class="col-6 mb-3" *ngIf="workOrder.technicians.length > 0">
                                <div *ngFor="let t of workOrder.technicians" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col text-truncate">{{t.text}}</div>
                                        <div class="col-auto">
                                            <a href="javascript:void();" (click)="deleteTechnician(t);" class="list-group-item-actions show">
                                                <fa-icon icon="trash"></fa-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-default" *ngIf="!showTechniciansSelect" (click)="editTechnicians();">
                                <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;Toevoegen
                            </button>
                            <select class="form-select" *ngIf="showTechniciansSelect" [(ngModel)]="newTechnicianId" (change)="addTechnician(newTechnicianId)" name="technician">
                                <option [value]="-1">Selecteer een uitvoerder</option>
                                <option *ngFor="let t of technicians" [value]="t.id">{{t.text}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="remark">Opmerking</label>
                        <div class="col">
                            <input type="text" class="form-control" name="remark" id="remark"
                                [(ngModel)]="workOrder.remark">
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label pt-0" for="isCancelled">Geannuleerd?</label>
                        <div class="col">
                            <label class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="isCancelled" 
                                id="isCancelled" [(ngModel)]="workOrder.isCancelled">
                                <span class="form-check-label"></span>
                            </label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <div class="btn-list flex-nowrap me-auto">
            <button type="button" class="btn btn-default me-1" (click)="myWorkOrderModal.hide();">
                Annuleren
            </button>
            <button type="button" class="btn btn-primary" (click)="updateWorkOrder()">
                Opslaan
            </button>
        </div>
    </div>
</app-modal>