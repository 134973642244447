import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'
import { IdTextDto } from './IdTextDto';

export class WorkOrderReferenceDto extends EntityReferenceDto {

    public description: string;
    public remark: string;
    public clientResidenceId: number;
    public clientResidenceResidentFirstName: string;
    public clientResidenceResidentLastName: string;
    public clientResidenceResidentPhone: string;
    public clientResidenceResidentIsOwner: boolean;
    public isCancelled: boolean;
    public requesterId: number;
    public requesterFirstName: string;
    public partOfDay: enums.TimeType;
    public labelName: string;
    public labelColor: string;
    public workOrderLabelId: number;
    public clientResidenceAddressId: number;
    public clientResidenceAddressLine: string;
    public date: Date;
    public technicians: IdTextDto[];
    public contractId: number;

    constructor(init?: Partial<WorkOrderReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}