import { Component, OnInit } from '@angular/core';

import * as c from '../../core';
import * as m from '../../models';
import { Router } from '@angular/router';

@Component({
    selector: 'contract-new',
    templateUrl: './contractnew.html'
})
export class ContractnewComponent implements OnInit {
    public contract: m.ContractDto;
    public type = m.ContractType;
    public selectedId: number;

    constructor(
        private router: Router,
        private dispatcher: c.Dispatcher) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.contract = new m.ContractDto({
            type: m.ContractType.private,
            reference: "",
            address: new m.AddressMiniDto({
                street: '',
                number: '',
                bus: '',
                cityPostalCode: '',
                cityName: ''
            }),
            startDate: new Date,
            endDate: new Date,
        });
    }

    public handleSaveIsNewContract = async () => {
        var adres = new m.AddressMiniDto({
            street: this.contract.address.street,
            number: this.contract.address.number,
            bus: this.contract.address.bus,
            cityId: this.selectedId
        });

        const request = new m.CreateContractRequest({
            type: this.contract.type,
            reference: this.contract.reference,
            contactAddress: adres,
            startDate: this.contract.startDate,
            endDate: this.contract.endDate,
        })

        let response = await this.dispatcher.send<m.CreateContractResponse>(request);
        this.contract = response.contract;

        this.returnToDetail();
    }

    returnToDetail() {
        this.router.navigate(['contracts/detail/', this.contract.id, 'edit'])
    }

}
