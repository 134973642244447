import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as auth from '../auth';
import * as e from '../../models/enums';
import { Translation } from './translation';
import { TranslateService } from './translate-service';

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform, OnDestroy {
    public userLanguage: e.Language = null;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    value: string = '';

    constructor(
        private auth: auth.AuthenticationService,
        private service: TranslateService
    ) {
        this.auth
            .userInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(u => {
                if (u == null) return;
                this.userLanguage = u.language;
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    transform(value: any): string {
        if (value === null)
            return null;
        if (value === undefined)
            return undefined;

        const resourceValue = this.service.translate(value);
        if (resourceValue !== null)
            return resourceValue;

        if (this.isTranslation(value))
            return this.parseTranslation(value);

        if (this.isBoolean(value))
            return this.parseBoolean(value);

        return `[NO TRANSLATION] ${value.toString()}`;
    }

    private parseTranslation(value: any): string {
        const code = Translation.getCode(this.userLanguage);
        const idx = value.findIndex({ language: code });

        if (idx !== -1) {
            return value[idx].text;
        }

        return value[0].text;
    }

    private parseBoolean(value: boolean) {
        if (value) {
            return this.service.translate('common.boolean_True');
        }

        return this.service.translate('common.boolean_False');
    }

    private isTranslation(value: any): boolean {
        if (value instanceof Array) {
            if (value.length > 0) {
                const translation = value[0] as Translation;

                // Could not use "instanceof Translation" since the type could be a JSON response
                if (translation.language !== null && translation.text !== null) {
                    return true;
                }
            }
        }

        return false;
    }

    private isBoolean(value: any): boolean {
        return typeof (value) === 'boolean';
    }
}