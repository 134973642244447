<app-modal #sordermodal>
    <div class="app-modal-header">
        {{ 'client.sortItems' | translate }}
    </div>
    <div class="app-modal-body">
        <form>
            <div class="row">
                <div class="col-lg-9">
                    <select name="sortNodes" [(ngModel)]="selectedNode" class="form-control" size="10">
                        <option *ngFor="let node of internalNodes" [ngValue]="node">
                            <span [innerHTML]="spacing(node)"></span>
                            {{node.title}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-3" style="display: inline-block; vertical-align: middle; float: none">
                    <div>
                        <button type="button" class="btn btn-default-alt sort-button" (click)="moveUp()">
                            &nbsp;<i class="fa fa-caret-up "></i>
                            {{'client.up' | translate}}
                        </button>
                    </div>
                    <div>
                        <button type="button" class="btn btn-default-alt sort-button" (click)="moveDown()">
                            &nbsp;<i class="fa fa-caret-down"></i>
                            &nbsp;{{'client.down' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" (click)="internalSave()">{{'client.save' | translate}}</button>
        <btn-undo (click)="sordermodal.hide()"></btn-undo>
    </div>
</app-modal>