import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import * as c from '@core';
import * as m from '@models';

@Component({
    selector: 'work-order-modal-technician',
    templateUrl: './work-order-modal-technician.html',
})
export class WorkOrderModalTechnicianComponent {
    public workOrder: m.WorkOrderReferenceDto;
    public workOrderTechnicianEdit: m.WorkOrderTechnicianEditReferenceDto;

    public performedWork: string = '';
    public deliveredMaterial: string = '';
    public installationData: string = '';
    public furtherActionNeeded: boolean = false;
    public furtherActionDescription: string = '';
    public hasError: boolean = false;
    bag: IValidationBag;
    public isNew: boolean = false;

    constructor(
        private dispatcher: c.Dispatcher
    ) { }

    @Output() onChange = new EventEmitter();

    @ViewChild('myWorkOrderModalTechnician')
    public myWorkOrderModalTechnician: c.ModalComponent;

    show(workOrder: m.WorkOrderReferenceDto) {
        this.workOrder = JSON.parse(JSON.stringify(workOrder));

        this.showTechnicianEdit();

        this.myWorkOrderModalTechnician.show();
    }

    async showTechnicianEdit() {
        const response = await this.dispatcher.send<m.GetDistinctWorkOrderTechnicianEditResponse>(new m.GetDistinctWorkOrderTechnicianEditRequest({ workOrderId: this.workOrder.id }))

        if (response.technicianEdit != null) {
            this.isNew = false;
            this.workOrderTechnicianEdit = response.technicianEdit;
            this.furtherActionNeeded = response.technicianEdit.furtherActionNeeded;
        }
        else {
            this.isNew = true;
            this.workOrderTechnicianEdit = new m.WorkOrderTechnicianEditReferenceDto({
                workOrderId: this.workOrder.id,
                performedWork: '',
                deliveredMaterial: '',
                installationData: '',
                furtherActionNeeded: false,
                furtherActionDescription: ''
            });
        }
    }

    async updateWorkOrderTechnician() {
        if (!this.isNew) {
            var request = new m.UpdateWorkOrderTechnicianEditRequest({
                workOrderId: this.workOrder.id,
                performedWork: this.workOrderTechnicianEdit.performedWork,
                deliveredMaterial: this.workOrderTechnicianEdit.deliveredMaterial,
                installationData: this.workOrderTechnicianEdit.installationData,
                furtherActionNeeded: this.furtherActionNeeded,
                furtherActionDescription: this.furtherActionNeeded? this.workOrderTechnicianEdit.furtherActionDescription : ''
            });

           let response =  await this.dispatcher.send<m.UpdateWorkOrderTechnicianEditResponse>(new m.UpdateWorkOrderTechnicianEditRequest(request));
            
            if (response.validationBag.isValid) {
                this.hasError = false;
                this.close();
                this.onChange.emit(null);
            } else {
                this.hasError = true;
                this.bag = response.validationBag;
            }
        
        }
        else {
            var request = new m.CreateWorkOrderTechnicianEditRequest({
                workOrderId: this.workOrder.id,
                performedWork: this.workOrderTechnicianEdit.performedWork,
                deliveredMaterial: this.workOrderTechnicianEdit.deliveredMaterial,
                installationData: this.workOrderTechnicianEdit.installationData,
                furtherActionNeeded: this.furtherActionNeeded,
                furtherActionDescription: this.furtherActionNeeded? this.workOrderTechnicianEdit.furtherActionDescription : ''
            });
            
            let response = await this.dispatcher.send<m.CreateWorkOrderTechnicianEditResponse>(new m.CreateWorkOrderTechnicianEditRequest(request));
        
            if (response.validationBag.isValid) {
                this.hasError = false;
                this.close();
                this.onChange.emit(null);
            } else {
                this.hasError = true;
                this.bag = response.validationBag;
            }
        }
    }

    public close(){
        this.isNew = false;
        this.furtherActionNeeded = false
        this.myWorkOrderModalTechnician.hide();
    }
}

