import { Routes } from '@angular/router';
import { ClientresidenceoverviewComponent } from './clientresidenceoverview.component';
import { ClientresidenceDetailComponent } from './clientresidencedetail.component';
import { ClientresidenceNewComponent } from 'src/app/main/clientresidence/clientresidencenew.component';

export const routes: Routes = [
    {
        path: '',
        component: ClientresidenceoverviewComponent
    },
    {
        path: ':id',
        component: ClientresidenceDetailComponent
    },
    {
        path: 'detail/:contractId/residence/:id',
        component: ClientresidenceDetailComponent
    },
    {
        path: 'createresidence/:contractId',
        component: ClientresidenceNewComponent
    }
];