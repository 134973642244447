import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserInfo } from './user-info';
import { Dispatcher } from '../http';
import { GetUserInfoRequest, GetUserInfoResponse } from 'src/app/models/requests/GetUserInfoRequestHandler';
import { LogOutUserResponse, LogOutUserRequest, UserRole } from 'src/app/models';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
    private userInfoSubject = new BehaviorSubject<UserInfo>(null);
    public redirectUrl: string;

    constructor(
        private router: Router,
        private dispatcher: Dispatcher
    ) {
    }

    public get userInfo$(): Observable<UserInfo> {
        return this.userInfoSubject.asObservable();
    }

    public get currentUserValue(): UserInfo {
        return this.userInfoSubject.value;
    }

    public async handleAuthentication() {
        var userInfo = await this.loadUserInfo();

        if (userInfo != null) {
            this.userInfoSubject.next(userInfo);

            if (this.isAdminOrPlanner(userInfo)) {
                this.router.navigate(['/main/work-order']);
            }
            if (this.isTechnician(userInfo)) {
                this.router.navigate(['/main/work-order/technician-overview']);
            }
        }
        else {
            this.router.navigate(['/login']);
        }
    }

    public isAdminOrPlanner(userinfo: UserInfo) {
        if (userinfo.roles.includes(UserRole.administrator) || userinfo.roles.includes(UserRole.planner))
            return true;
    }

    public isTechnician(userinfo: UserInfo) {
        if (userinfo.roles.includes(UserRole.technician))
            return true;
    }

    public async handleAuthenticationFromSettings() {
        var userInfo = await this.loadUserInfo();

        if (userInfo != null) {
            this.userInfoSubject.next(userInfo);

            if (this.isAdminOrPlanner(userInfo)) {
                this.router.navigate(['/main/work-order']);
            }
            if (this.isTechnician(userInfo)) {
                this.router.navigate(['/main/work-order/technician-overview']);
            }
        }
        else {
            this.router.navigate(['/login']);
        }
    }

    public async logout() {
        const response = await this.dispatcher.send<LogOutUserResponse>(new LogOutUserRequest());
        if (response.success) {
            this.router.navigate(['/login'])
            localStorage.clear();
        }
    }

    public isAuthenticated(): boolean {
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    private async loadUserInfo(): Promise<UserInfo> {
        let response = await this.dispatcher.send<GetUserInfoResponse>(new GetUserInfoRequest());

        if (response.isLoggedIn) {
            let info = new UserInfo({
                id: response.userId,
                name: response.name,
                roles: response.roles
            });

            return info;
        }

        return null;
    }
}