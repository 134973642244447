

export enum UserActionLog {
    login = 8001,
    logout = 8002,
}

export namespace UserActionLog {
    export function keys(): number[] {
        return [8001, 8002];
    }
}

document['enums'][8001] = 'common.userActionLog_Login';
document['enums'][8002] = 'common.userActionLog_Logout';