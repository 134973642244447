

export enum UserRole {
    administrator = 3001,
    technician = 3002,
    planner = 3003,
}

export namespace UserRole {
    export function keys(): number[] {
        return [3001, 3002, 3003];
    }
}

document['enums'][3001] = 'common.userRole_Administrator';
document['enums'][3002] = 'common.userRole_Technician';
document['enums'][3003] = 'common.userRole_Planner';