export enum ValidationLevel {
    error = 1,
    warning = 2,
    notification = 3,
    debug = 4
}

export namespace ValidationLevel {
    export function keys(): number[] {
        return [1, 2, 3, 4];
    }
}

// Do not map this because it will conflict with language enum
// document['enums'][1] = '';
// document['enums'][2] = '';
// document['enums'][3] = '';
// document['enums'][4] = '';