import * as enums from '../enums';
import { EntityDto } from './EntityDto'

export class UserAccountDto extends EntityDto {

    public firstName: string;
    public familyName: string;
    public shortNameTech: string;
    public shortNamePlan: string;
    public email: string;
    public isActive: boolean;
    public status: enums.UserStatus;
    public roles: enums.UserRole[];

    constructor(init?: Partial<UserAccountDto>) {
        super(init);
        Object.assign(this, init);
    }
}