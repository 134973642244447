import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class UpdateUserRequest implements IRequest<UpdateUserResponse> {
    constructor(init?: Partial<UpdateUserRequest>) {
        Object.assign(this, init);
    }

    id: number;
    firstName: string;
    familyName: string;
    shortNameTech: string;
    shortNamePlan: string;
    email: string;
    password: string;
    status: enums.UserStatus;
    roles: enums.UserRole[];
    public $type: string = 'Imversa.Web.RequestHandlers.Security.UserAccounts.UpdateUserRequest';
}

export class UpdateUserResponse extends ImversaResponse {

    constructor(init?: Partial<UpdateUserResponse>) {
        super();
        Object.assign(this, init);
    }
} 