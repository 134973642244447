import { Component, OnInit } from '@angular/core';
import * as c from './core';

@Component({
    selector: 'app-root',
    templateUrl: './app.html'
})
export class AppComponent implements OnInit {
    constructor(
        private auth: c.AuthenticationService
    ) {
       
    }

    ngOnInit(): void {
        this.auth.handleAuthentication();
    }
}
