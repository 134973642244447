import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetAllAbsencesRequest implements IRequest<GetAllAbsencesResponse> {
    constructor(init?: Partial<GetAllAbsencesRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.HR.Absences.GetAllAbsencesRequest';
}

export class GetAllAbsencesResponse extends ImversaResponse {
    absences: dtos.AbsenceDto[];

    constructor(init?: Partial<GetAllAbsencesResponse>) {
        super();
        Object.assign(this, init);
    }
} 