import { Component, Input } from '@angular/core';

@Component({
    selector: 'btn-delete',
    template: `
<button type="button" class="btn btn-danger" title="{{ 'client.remove' | translate }}"
        [disabled]="disabled || loading"
        (click)="onClick()">
    <fa-icon [icon]="icon" [spin]="loading" [fixedWidth]="true"></fa-icon>
    <ng-container *ngIf="hasTitle">&nbsp;{{innerTitle}}</ng-container>
</button>`
})
export class BtnDelete {
    loading: boolean = false;
    hasTitle: boolean = false;
    innerTitle: string = null;
    icon: string = 'trash-alt';

    @Input() disabled: boolean;
    @Input() set title(value: string) {
        this.hasTitle = true;
        this.innerTitle = value;
    }

    @Input() onDelete: any = null;

    public async onClick() {
        this.loading = true;
        this.icon = 'sync-alt';
        await this.onDelete();
        this.loading = false;
        this.icon = 'trash-alt';
    }
}