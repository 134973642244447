import { Component, Input } from '@angular/core';

@Component({
    selector: 'btn-undo',
    template: `
<button type="button" class="btn btn-default">
    <i class="fa fa-fw fa-undo" *ngIf=!loading></i>
    &nbsp;{{ title | translate }}
</button>`
})
export class BtnUndo {
    loading: boolean = false;
    @Input() title: string = 'client.cancel';
}