import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'

export class ClientResidenceHistoryReferenceDto extends EntityReferenceDto {

    public remark: string;
    public date: Date;

    constructor(init?: Partial<ClientResidenceHistoryReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}