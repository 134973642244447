import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn: 'root',
})
export class LoggedIn implements CanActivate {
    constructor(
        private auth: AuthenticationService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return this.auth.isAuthenticated();
    }
}