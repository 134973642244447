import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

import { throwError, Observable } from 'rxjs';
import * as o from 'rxjs/operators';

@Injectable()
export class LogOutInterceptor implements HttpInterceptor {
    constructor(
        private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                o.catchError((error: any) => {
                    if (error && error.status === 401) {
                        this.router.navigate(['/login']);
                    }

                    return throwError(() => error);
                })
            );
    }
}