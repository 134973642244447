import * as enums from '../enums';

import { AddressMiniInputDto } from './AddressMiniInputDto';
import { ResidentReferenceDto } from './ResidentReferenceDto';

export class CreateTaskDto  {

    public timeZone: enums.TimeType;
    public resident: ResidentReferenceDto;
    public address: AddressMiniInputDto;
    public description: string;
    public appointmentMaker: string;
    public technicians: string[];
    public remark: string;

    constructor(init?: Partial<CreateTaskDto>) {
        Object.assign(this, init);
    }
}