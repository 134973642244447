import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class ChangeDateRequest implements IRequest<ChangeDateResponse> {
    constructor(init?: Partial<ChangeDateRequest>) {
        Object.assign(this, init);
    }

    selectedDate: Date;
    public $type: string = 'Imversa.Web.RequestHandlers.Home.ChangeDateRequest';
}

export class ChangeDateResponse extends ImversaResponse {
    technicians: dtos.UserAccountReferenceDto[];

    constructor(init?: Partial<ChangeDateResponse>) {
        super();
        Object.assign(this, init);
    }
} 