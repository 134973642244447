<div class="container-xl">
    <div class="page-header d-print-none">
        <div class="row align-items-center">
            <div class="col">
                <h1 class=" d-flex justify-content-center">
                    Importeer werkbonnen
                </h1>
                <div class="page-pretitle d-flex justify-content-center">
                    Laad een Excel bestand op om automatisch werkbonnen aan te maken.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-body">
    <div class="container-xl">
        <file-upload #fileUpload (fileUploaded)="onFileUploaded($event)"></file-upload>

        <div class="mt-2" *ngIf="downloadKey">
            <p> Aantal lijnen verwerkt: {{amountParsed}}</p>
            <p> Aantal lijnen die niet verwerkt konden worden: {{linesWithIssues.length}} </p>

            <div class="table-responsive" *ngIf="linesWithIssues.length > 0">
                <p> De volgende lijnen konden niet verwerkt worden: </p>
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Afspraak</th>
                            <th>Naam</th>
                            <th>Adres</th>
                            <th>tel.nr</th>
                            <th>Uitvoering</th>
                            <th>Afgesproken met</th>
                            <th>Uitgevoerd door</th>
                            <th>Opmerkingen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let line of linesWithIssues">
                            <td>{{line.date | date: 'shortDate'}}</td>
                            <td>{{line.timeZone}}</td>
                            <td class="text-muted">{{line.name}}</td>
                            <td class="text-muted">{{line.address}}</td>
                            <td class="text-muted">{{line.telephoneNumber}}</td>
                            <td>{{line.description}}</td>
                            <td>{{line.appointmentMaker}}</td>
                            <td>{{line.technicians}}</td>
                            <td>{{line.remark}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="newUsers">
                <p>
                    Deze nieuwe gebruikers werden toegevoegd:
                    <span *ngFor="let user of newUsers; let i=index; let isLast=last">
                        <span *ngIf="i <= newUsers.length">&nbsp;{{user}}</span>
                        <span class="list-format" *ngIf="!isLast">&#44;&nbsp;</span>
                    </span>
                </p>
            </div>

            <div *ngIf="downloadKey">
                <button type="button" class="btn btn-default btn-sm" (click)="download()">
                    <fa-icon icon="download"></fa-icon>
                </button>
            </div>
        </div>

        <app-modal #myNewTechniciansModal>
            <div class="app-modal-header">
                <h5 class="modal-title">Nieuwe techniekers</h5>
            </div>
            <div class="app-modal-body">
                <div class="card mb-3">
                    <div class="card-body" *ngIf="newUsers">
                        <form>
                            <div class="form-group mb-3 row">
                                <label class="form-label col-3 col-form-label" for="technician">Uitvoerders</label>
                                <div class="col">
                                    <div class="list-group" class="col-6 mb-3" *ngIf="newUsers.length > 0">
                                        <div *ngFor="let user of newUsers" class="list-group-item">
                                            <div class="row align-items-center">
                                                <div class="col text-truncate">{{user}}</div>
                                                <div class="col-auto"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer">
                <div class="btn-list flex-nowrap me-auto">
                    <button type="button" class="btn btn-default me-1" (click)="abortImport()">
                        Import annuleren
                    </button>
                    <button type="button" class="btn btn-primary" (click)="continueImport()">
                        Import uitvoeren
                    </button>
                </div>
            </div>
        </app-modal>
    </div>
</div>