import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class SearchClientResidenceRequest implements IRequest<SearchClientResidenceResponse> {
    constructor(init?: Partial<SearchClientResidenceRequest>) {
        Object.assign(this, init);
    }

    name: string;
    street: string;
    number: number;
    city: string;
    page: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Home.SearchClientResidenceRequest';
}

export class SearchClientResidenceResponse extends ImversaResponse {
    residences: dtos.ClientResidenceReferenceDto[];
    total: number;
    pageSize: number;

    constructor(init?: Partial<SearchClientResidenceResponse>) {
        super();
        Object.assign(this, init);
    }
} 