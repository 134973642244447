

export enum ContractType {
    company = 2001,
    private = 2002,
    tender = 2003,
}

export namespace ContractType {
    export function keys(): number[] {
        return [2001, 2002, 2003];
    }
}

document['enums'][2001] = 'common.contractType_Company';
document['enums'][2002] = 'common.contractType_Private';
document['enums'][2003] = 'common.contractType_Tender';