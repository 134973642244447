<div class="container">
    <div class="m-subheader ">
        <div class="d-flex align-items-center">
            <div class="mr-auto">
                <h3 class="m-subheader__title m-subheader__title">Nieuw Contract</h3>
            </div>
        </div>
    </div>

    <div class="m-content">
        <form class="mb-3">
            <div class="card">
                <h5 class="card-header">
                    Contract informatie:
                </h5>
                <div class="card-body">

                    <div class="form-group d-flex justify-content-start">
                        <label for="referentie" class="col-form-label w-25">Referentie:</label>
                        <input type="text" name="referentie" [(ngModel)]="contract.reference">
                    </div>

                    <div class="form-group d-flex justify-content-start">
                        <label for="contractType" class="col-form-label w-25">Type:</label>
                        <div class="form-check form-check-inline">
                            <input type="radio" class="form-check-input" name="vakantie" id="inlineRadio1" [(ngModel)]="contract.type" [value]="type.company">
                            <label class="form-check-label" for="inlineRadio1">Bedrijf</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" class="form-check-input" name="ziekte" id="inlineRadio2" [(ngModel)]="contract.type" [value]="type.private">
                            <label class="form-check-label" for="inlineRadio2">Particulier</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" class="form-check-input" name="ziekte" id="inlineRadio3" [(ngModel)]="contract.type" [value]="type.tender">
                            <label class="form-check-label" for="inlineRadio3">Aanbesteding</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <h5 class="card-header">
                    Factuuradres:
                </h5>
                <div class="card-body">
                    <address-detail [object]="contract" [(selectedCityId)]="selectedId"></address-detail>
                </div>
            </div>

            <div class="card" *ngIf="contract.type == type.tender">
                <h5 class="card-header">
                    Duurtijd contract:
                </h5>
                <div class="card-body">
                    <div class="form-group d-flex justify-content-start">
                        <label for="startDatum" class="col-form-label w-25">Startdatum:</label>
                        <app-datetime name="startDatum" [(ngModel)]="contract.startDate"></app-datetime>
                    </div>
                    <div class="form-group d-flex justify-content-start">
                        <label for="eindDatum" class="col-form-label w-25">Einddatum:</label>
                        <app-datetime name="eindDatum" [(ngModel)]="contract.endDate"></app-datetime>
                    </div>
                </div>
            </div>
        </form>

        <btn-save [onSave]="handleSaveIsNewContract"></btn-save>
    </div>

</div>