import * as enums from '../enums';
import { EntityDto } from './EntityDto'

export class ResidentDto extends EntityDto {

    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;

    constructor(init?: Partial<ResidentDto>) {
        super(init);
        Object.assign(this, init);
    }
}