import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class UpdateWorkOrderRequest implements IRequest<UpdateWorkOrderResponse> {
    constructor(init?: Partial<UpdateWorkOrderRequest>) {
        Object.assign(this, init);
    }

    workOrderId: number;
    description: string;
    technicianIds: number[];
    date: Date;
    remark: string;
    requesterId: number;
    residentFirstName: string;
    residentLastName: string;
    residentPhone: string;
    addressStreet: string;
    addressNumber: string;
    addressBus: string;
    isCancelled: boolean;
    cityId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.WorkOrders.UpdateWorkOrderRequest';
}

export class UpdateWorkOrderResponse extends ImversaResponse {

    constructor(init?: Partial<UpdateWorkOrderResponse>) {
        super();
        Object.assign(this, init);
    }
} 