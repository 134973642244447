import { Component, ViewChild } from '@angular/core';

import * as c from '@core';
import * as m from '@models';

@Component({
    templateUrl: './import.html'
})
export class ImportComponent {
    downloadKey: string;
    key: string;
    uploadedFile: c.FileInfo = null;
    amountParsed: number = 0;
    linesWithIssues: m.ImportExcelLineDto[] = [];
    newUsers: string[] = [];

    constructor(
        private dispatcher: c.Dispatcher
    ) { }

    @ViewChild('fileUpload')
    public fileUpload: c.FileUpload;

    @ViewChild('myNewTechniciansModal')
    public myNewTechniciansModal: c.ModalComponent;

    public async onFileUploaded(fileInfo: c.FileInfo) {
        if (fileInfo == null) return;
        this.uploadedFile = fileInfo;

        this.showNewTechnicians();
    }

    async showNewTechnicians() {
        let request = new m.ReadTechniciansFromExcelRequest({
            keyString: this.uploadedFile.key
        });

        let response = await this.dispatcher.send<m.ReadTechniciansFromExcelResponse>(new m.ReadTechniciansFromExcelRequest(request));
        this.newUsers = response.usersToAdd;

        if (response.usersToAdd.length == 0) {
            this.continueImport();
        }
        else {
            this.myNewTechniciansModal.show();
        }
    }

    async download() {
        window.open("/download-file?key=" + this.downloadKey);
    }

    abortImport() {
        this.newUsers = [];
        this.myNewTechniciansModal.hide()
    }

    async continueImport() {
        this.myNewTechniciansModal.hide()
        let request = new m.ExcelToWordRequest({
            keyString: this.uploadedFile.key
        });

        let response = await this.dispatcher.send<m.ExcelToWordResponse>(new m.ExcelToWordRequest(request));
        this.amountParsed = response.amountParsed;
        this.linesWithIssues = response.linesWithIssues;
        this.downloadKey = response.key;
        this.newUsers = response.usersToAdd;
    }
}
