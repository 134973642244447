import { Component, OnInit } from '@angular/core';

import * as c from '../../core';
import * as m from '../../models';

@Component({
  selector: 'residentsoverview',
  templateUrl: './residentsoverview.component.html'
})
export class ResidentsoverviewComponent implements OnInit {
  public residents: m.ResidentDto[];
  public filteredResidents: m.ResidentDto[];
  public clickedResident: m.ResidentDto[] = [];
  public listFilter: any;

  private _listFilter: string;
  highlightResidents: Array<boolean> = [];

  constructor(private dispatcher: c.Dispatcher) { }

  ngOnInit() {
  }
}
