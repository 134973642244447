import * as e from '../../models/enums';

export class Translation {
    constructor(
        public language?: string,
        public text?: string
    ) { }

    static getCode(language: e.Language): string {
        switch (language) {
        case e.Language.dutch:
            return 'nl';
        case e.Language.french:
            return 'fr';
        case e.Language.english:
            return 'en';
        default:
            return '';
        }
    }

    static createTranslations(): Translation[] {
        return e.Language.keys().map(l => new Translation(this.getCode(l), ''));
    }
}