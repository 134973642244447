import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class UpdateContractRequest implements IRequest<UpdateContractResponse> {
    constructor(init?: Partial<UpdateContractRequest>) {
        Object.assign(this, init);
    }

    id: number;
    type: enums.ContractType;
    reference: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactAddress: dtos.AddressMiniDto;
    startDate: Date;
    endDate: Date;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.Contracts.UpdateContractRequest';
}

export class UpdateContractResponse extends ImversaResponse {

    constructor(init?: Partial<UpdateContractResponse>) {
        super();
        Object.assign(this, init);
    }
} 