import * as enums from '../enums';
import { EntityDto } from './EntityDto'

export class WorkOrderLabelDto extends EntityDto {

    public labelName: string;
    public labelColor: string;

    constructor(init?: Partial<WorkOrderLabelDto>) {
        super(init);
        Object.assign(this, init);
    }
}