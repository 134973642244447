import * as enums from '../enums';
import { EntityDto } from './EntityDto'
import { ClientResidenceReferenceDto } from './ClientResidenceReferenceDto';
import { ContractReferenceDto } from './ContractReferenceDto';
import { UserAccountReferenceDto } from './UserAccountReferenceDto';
import { WorkOrderLabelReferenceDto } from './WorkOrderLabelReferenceDto';

export class WorkOrderDto extends EntityDto {

    public contract: ContractReferenceDto;
    public date: Date;
    public isCancelled: boolean;
    public clientResidence: ClientResidenceReferenceDto;
    public requester: UserAccountReferenceDto;
    public workOrderLabel: WorkOrderLabelReferenceDto;
    public partOfDay: enums.TimeType;
    public description: string;
    public remark: string;
    public technicians: UserAccountReferenceDto[];

    constructor(init?: Partial<WorkOrderDto>) {
        super(init);
        Object.assign(this, init);
    }
}