import { Routes } from "@angular/router";
import { ClientresidenceDetailComponent } from "../clientresidence";
import { ContractoverviewComponent } from "./contractoverview.component";
import { ContractDetailComponent } from "./contract-detail.component";
import { ContractnewComponent } from "src/app/main/contracts/contractnew.component";

export const routes: Routes = [
    {
        path: '',
        component: ContractoverviewComponent
    },
    {
        path: 'detail/:id',
        component: ContractDetailComponent
    },
    {
        path: 'detail/:id/edit',
        component: ContractDetailComponent
    },
    {
        path: 'create',
        component: ContractnewComponent
    },

    //Clientresidence routes
    {
        path: 'detail/:id/residence/:residenceId',
        component: ClientresidenceDetailComponent
    }
];



