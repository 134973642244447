<div class="page-wrapper">
    <div class="container-xl">
        <div class="page-header d-print-none">
            <div class="align-items-center">
                <h1 class=" d-flex justify-content-center">
                    Werkbonnen
                </h1>
                <div class="page-pretitle d-flex justify-content-center">
                    Beheerscherm voor al de werkbonnen in de databank.
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <aside class="col-3 flex-column align-items-stretch flex-shrink-0">
                <div class="card mt-2">
                    <div class="card-body">
                        <div class="mb-3">
                            <div class="input-group">
                                <span class="input-group-text">
                                    <fa-icon icon="search"></fa-icon>
                                </span>
                                <input type="text" class="form-control" placeholder="Zoeken" autocomplete="off" (ngModelChange)="delayedRefresh()" [(ngModel)]="listFilter">
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="requester">Aanvrager</label>
                            <select class="form-select" [(ngModel)]="requesterId" name="requester" (ngModelChange)="delayedRefresh()">
                                <option value=""></option>
                                <option *ngFor="let r of requesters" [value]="r.id">{{r.text}}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="technician">Technieker</label>
                            <select class="form-select" [(ngModel)]="technicianId" name="technician" (ngModelChange)="delayedRefresh()">
                                <option value=""></option>
                                <option *ngFor="let t of technicians" [value]="t.id">{{t.text}}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="searchDate">Datum</label>
                            <app-datetime [(ngModel)]="searchDate" (ngModelChange)="delayedRefresh()"></app-datetime>
                        </div>
                    </div>
                </div>
            </aside>
            <div class="col-9">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="page-title">
                                Overzicht
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card mt-2" *ngFor="let workorder of filteredWorkOrders;">
                    <div class="card-status-top bg-danger" *ngIf="workorder.isCancelled"></div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <span class="w-50">
                                <a href="javascript:void(0);" class="me-2" (click)="downloadWorkorder(workorder.id)">
                                    <fa-icon icon="download"></fa-icon>
                                </a>
                                <span *ngIf="workorder.labelName" class="badge rounded-pill" [ngStyle]="{'background-color': workorder.labelColor}">
                                    {{workorder.labelName}}
                                </span>
                                <b href="javascript:void(0)" class="fw-bold">
                                    {{workorder.date | date:'dd/MM/yyyy'}} {{workorder.partOfDay | enum | translate}}
                                </b>
                                <a href="javascript:void(0);" class="fw-bold" (click)="openWorkOrderModal(workorder)">
                                {{workorder.clientResidenceResidentFirstName}}
                                {{workorder.clientResidenceResidentLastName}} </a> - {{workorder.description}}
                            </span>
                            <span>
                                <div class="d-flex justify-content-end">
                                    Techniekers:&nbsp;
                                    <span *ngFor="let t of workorder.technicians; let isLast=last">
                                        {{t.text}}{{!isLast ? '&nbsp;/&nbsp;' : ''}}
                                    </span>
                                </div>
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="w-50">{{workorder.clientResidenceAddressLine}}</span>
                            <span>Afgesproken met: {{workorder.requesterFirstName}}</span>
                        </div>
                    </div>
                    <div class="card-status-bottom bg-danger" *ngIf="workorder.isCancelled"></div>
                </div>
                <work-order-modal #editModal (onChange)="refresh()" [requesters]="requesters" [technicians]="technicians"></work-order-modal>

                <div class="d-flex justify-content-center mt-3 mb-3">
                    <pagination #pagination [(currentPage)]="currentPage" [total]="total" [pageSize]="pageSize" (currentPageChange)="pageChanged($event)"></pagination>
                </div>
            </div>
    
        </div>
    </div>
</div>