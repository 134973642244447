import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class PrintPlanningRequest implements IRequest<PrintPlanningResponse> {
    constructor(init?: Partial<PrintPlanningRequest>) {
        Object.assign(this, init);
    }

    taskId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Home.PrintPlanningRequest';
}

export class PrintPlanningResponse extends ImversaResponse {
    key: string;

    constructor(init?: Partial<PrintPlanningResponse>) {
        super();
        Object.assign(this, init);
    }
} 