import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class UpdateWorkOrderTechnicianEditRequest implements IRequest<UpdateWorkOrderTechnicianEditResponse> {
    constructor(init?: Partial<UpdateWorkOrderTechnicianEditRequest>) {
        Object.assign(this, init);
    }

    workOrderId: number;
    performedWork: string;
    deliveredMaterial: string;
    installationData: string;
    furtherActionNeeded: boolean;
    furtherActionDescription: string;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.WorkOrders.UpdateWorkOrderTechnicianEditRequest';
}

export class UpdateWorkOrderTechnicianEditResponse extends ImversaResponse {

    constructor(init?: Partial<UpdateWorkOrderTechnicianEditResponse>) {
        super();
        Object.assign(this, init);
    }
} 