import { Component, Input } from '@angular/core';
import { NgForm } from "@angular/forms";

@Component({
    selector: 'btn-save',
    template: `
<button type="button" class="btn btn-primary"
        [disabled]="disabled || loading"
        (click)="onClick()">
    <fa-icon [icon]="icon" [spin]="loading" [fixedWidth]="true"></fa-icon>
    &nbsp;{{title | translate}}
</button>`
})
export class BtnSave {
    loading: boolean = false;
    icon: string = 'save';

    @Input() disabled: boolean;
    @Input() title: string = 'client.save';
    @Input() form: NgForm;
    @Input() onSave: any = null;

    public async onClick() {
        if (this.form && this.form.invalid) {
            return;
        }

        try {
            this.loading = true;
            this.icon = 'sync-alt';

            await this.onSave();
        } catch (error) {

        } finally {
            this.loading = false;
            this.icon = 'save';
        }
    }
}