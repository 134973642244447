import { Routes } from '@angular/router';
import { AbsencesOverviewComponent } from './absencesoverview.component';
import { AbsencedetailComponent } from './absencedetail.component';

export const routes: Routes = [
    {
        path: '',
        component: AbsencesOverviewComponent
    },
    {
        path: ':id',
        component: AbsencedetailComponent
    },
    {
        path: ':id/edit',
        component: AbsencedetailComponent
    },
    {
        path: 'create',
        component: AbsencedetailComponent
    }
];