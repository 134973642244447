import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetDistinctWorkOrderTechnicianEditRequest implements IRequest<GetDistinctWorkOrderTechnicianEditResponse> {
    constructor(init?: Partial<GetDistinctWorkOrderTechnicianEditRequest>) {
        Object.assign(this, init);
    }

    workOrderId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.WorkOrders.GetDistinctWorkOrderTechnicianEditRequest';
}

export class GetDistinctWorkOrderTechnicianEditResponse extends ImversaResponse {
    technicianEdit: dtos.WorkOrderTechnicianEditReferenceDto;

    constructor(init?: Partial<GetDistinctWorkOrderTechnicianEditResponse>) {
        super();
        Object.assign(this, init);
    }
} 