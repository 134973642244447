import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class DownloadWorkOrderRequest implements IRequest<DownloadWorkOrderResponse> {
    constructor(init?: Partial<DownloadWorkOrderRequest>) {
        Object.assign(this, init);
    }

    workOrderId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.WorkOrders.DownloadWorkOrderRequest';
}

export class DownloadWorkOrderResponse extends ImversaResponse {
    key: string;

    constructor(init?: Partial<DownloadWorkOrderResponse>) {
        super();
        Object.assign(this, init);
    }
} 