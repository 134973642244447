import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class ImportExcelAddressListRequest implements IRequest<ImportExcelAddressListResponse> {
    constructor(init?: Partial<ImportExcelAddressListRequest>) {
        Object.assign(this, init);
    }

    keyString: string;
    contractId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.Contracts.ImportExcelAddressListRequest';
}

export class ImportExcelAddressListResponse extends ImversaResponse {
    success: boolean;
    correctCities: any[];

    constructor(init?: Partial<ImportExcelAddressListResponse>) {
        super();
        Object.assign(this, init);
    }
} 