import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class CreateAbsenceRequest implements IRequest<CreateAbsenceResponse> {
    constructor(init?: Partial<CreateAbsenceRequest>) {
        Object.assign(this, init);
    }

    userId: number;
    type: enums.AbsenceType;
    startTime: Date;
    endTime: Date;
    remark: string;
    public $type: string = 'Imversa.Web.RequestHandlers.HR.Absences.CreateAbsenceRequest';
}

export class CreateAbsenceResponse extends ImversaResponse {
    absence: dtos.AbsenceDto;

    constructor(init?: Partial<CreateAbsenceResponse>) {
        super();
        Object.assign(this, init);
    }
} 