import { Component, Input } from '@angular/core';
import { NgForm } from "@angular/forms";

@Component({
    selector: 'btn-add',
    template: `
<button type="button" class="btn btn-primary"
        [disabled]="disabled || loading"
        (click)="onClick()">
    <fa-icon [icon]="icon" [spin]="loading" [fixedWidth]="true"></fa-icon>
    &nbsp;Voeg toe
</button>`
})
export class BtnAdd {
    loading: boolean = false;
    icon: string = 'plus';

    @Input() disabled: boolean;
    @Input() addToepassing: any = null;

    public async onClick() {
        this.loading = true;
        this.icon = 'sync-alt';
        await this.addToepassing();
        this.loading = false;
        this.icon = 'plus';
    }

}