import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class DeleteContractRequest implements IRequest<DeleteContractResponse> {
    constructor(init?: Partial<DeleteContractRequest>) {
        Object.assign(this, init);
    }

    id: number;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.Contracts.DeleteContractRequest';
}

export class DeleteContractResponse extends ImversaResponse {
    succeeded: boolean;
    reason: string;

    constructor(init?: Partial<DeleteContractResponse>) {
        super();
        Object.assign(this, init);
    }
} 