import { Injectable, ErrorHandler } from '@angular/core';
import { ExceptionService } from './exception.service';

@Injectable()
export class ExceptionHandler implements ErrorHandler {
    constructor(private exceptionService: ExceptionService) { }

    handleError(error: any): void {
        this.exceptionService.registerError(error);
    }
}