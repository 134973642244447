

export enum TimeType {
    morning = 5001,
    afternoon = 5002,
    unknown = 5003,
}

export namespace TimeType {
    export function keys(): number[] {
        return [5001, 5002, 5003];
    }
}

document['enums'][5001] = 'common.timeType_Morning';
document['enums'][5002] = 'common.timeType_Afternoon';
document['enums'][5003] = 'common.timeType_Unknown';