

export enum JobStatus {
    started = 6001,
    succeeded = 6002,
    failed = 6003,
    timeout = 6004,
}

export namespace JobStatus {
    export function keys(): number[] {
        return [6001, 6002, 6003, 6004];
    }
}

document['enums'][6001] = 'common.jobStatus_Started';
document['enums'][6002] = 'common.jobStatus_Succeeded';
document['enums'][6003] = 'common.jobStatus_Failed';
document['enums'][6004] = 'common.jobStatus_Timeout';