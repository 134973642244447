import { Component, OnInit } from '@angular/core';

import * as c from '../../core';
import * as m from '../../models';
import { AbsenceDto } from '../../models';

@Component({
    selector: 'absencesoverview',
    templateUrl: './absencesoverview.html',
})
export class AbsencesOverviewComponent implements OnInit {
    public absences: m.AbsenceDto[];

    public _listFilter: string;
    public filteredAbsences: m.AbsenceDto[] = [];

    public clickedAbsences: m.AbsenceDto[] = [];
    public isClicked: boolean;
    highlightAbsences: Array<boolean> = [];
    public idsToDelete: Array<number> = [];

    public type: m.AbsenceType;

    public currentDate: Date = new Date();

    constructor(private dispatcher: c.Dispatcher) {
    }

    ngOnInit() {
        this.refresh();
        this.currentDate.setHours(0, 0, 0, 0);
    }

    async refresh() {
        let response = await this.dispatcher.send<m.GetAllAbsencesResponse>(new m.GetAllAbsencesRequest());
        this.absences = response.absences
        this.filteredAbsences = response.absences.sort((a, b) => this.sortDates(b.startTime, a.startTime))
    }

    sortDates(a: any, b: any): number {
        var aDate = new Date(a);
        var bDate = new Date(b);

        return aDate.getTime() - bDate.getTime();
    }

    get listFilter(): string {
        return this._listFilter;
    }

    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredAbsences = this.listFilter ? this.performFilter(this.listFilter) : this.absences;
    }

    performFilter(filterBy: string): AbsenceDto[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.absences.filter((absence: AbsenceDto) => absence.userAccountFamilyName.toLocaleLowerCase().indexOf(filterBy) !== -1);
    }

    filterFutureAbsences() {
        this.emptyAllArays();
        this.filteredAbsences = this.absences.filter((absence: m.AbsenceDto) => {
            let dStartTime = new Date(absence.startTime);
            return dStartTime >= this.currentDate;
        });
    }

    filterPastAbsences() {
        this.emptyAllArays();
        this.filteredAbsences = this.absences.filter((absence: m.AbsenceDto) => {
            let dStartTime = new Date(absence.startTime);
            return dStartTime < this.currentDate;
        });
    }

    toggleAbsenceField(absence: m.AbsenceDto): void {
        const idx = this.clickedAbsences.indexOf(absence);
        if (idx === -1) {
            this.clickedAbsences.push(absence);
            this.idsToDelete.push(absence.id);
        } else {
            this.clickedAbsences.splice(idx, 1);
            this.idsToDelete.splice(idx, 1);
        }
    }

    isRemoveDisabled(): boolean {
        return this.clickedAbsences.length == 0;
    }

    isEditDisabled(): boolean {
        return this.clickedAbsences.length == 0 || this.clickedAbsences.length > 1;
    }

    getSelectedAbsenceId(): number {
        return this.clickedAbsences.length > 0 ? this.clickedAbsences[0]['id'] : 0;
    }

    async deleteAbsence() {
        for (let i of this.idsToDelete) {
            var response = await this.dispatcher.send<m.DeleteAbsenceResponse>(new m.DeleteAbsenceRequest({ id: i }));
        }
        this.emptyAllArays();
        this.refresh();
    }

    showZiekte() {
        this.emptyAllArays();
        this.filteredAbsences = this.absences.filter((absence: m.AbsenceDto) => absence.type === m.AbsenceType.sickness);
    }

    showVakantie() {
        this.emptyAllArays();
        this.filteredAbsences = this.absences.filter((absence: m.AbsenceDto) => absence.type === m.AbsenceType.vacation);
    }

    showOveruren() {
        this.emptyAllArays();
        this.filteredAbsences = this.absences.filter((absence: m.AbsenceDto) => absence.type === m.AbsenceType.overtime);
    }

    emptyAllArays(): void {
        this.clickedAbsences = [];
        this.idsToDelete = [];
        this.highlightAbsences = [];
    }

}


