

export enum Parameter {
    absenceType = 1,
    contractType = 2,
    userRole = 3,
    userStatus = 4,
    timeType = 5,
    jobStatus = 6,
    language = 7,
    userActionLog = 8,
}

export namespace Parameter {
    export function keys(): number[] {
        return [1, 2, 3, 4, 5, 6, 7, 8];
    }
}

document['enums'][1] = 'common.parameter_AbsenceType';
document['enums'][2] = 'common.parameter_ContractType';
document['enums'][3] = 'common.parameter_UserRole';
document['enums'][4] = 'common.parameter_UserStatus';
document['enums'][5] = 'common.parameter_TimeType';
document['enums'][6] = 'common.parameter_JobStatus';
document['enums'][7] = 'common.parameter_Language';
document['enums'][8] = 'common.parameter_UserActionLog';