import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'

export class ResidentReferenceDto extends EntityReferenceDto {

    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public teamLeaderId: number;
    public cellular: string;

    constructor(init?: Partial<ResidentReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}