import { Component, ViewChild } from '@angular/core';
import { ExceptionService } from './exception.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'exception-modal',
    templateUrl: './exception-modal.template.html'
})
export class ExceptionModalComponent {
    public errorMessage: string;

    @ViewChild('errormodal') errorModal: ModalComponent;

    constructor(private exceptionService: ExceptionService) {
        this.exceptionService.getErrors().subscribe(error => {
            if (error != null && this.errorModal !== undefined) {
                this.errorMessage = error.exceptionMessage;
                this.errorModal.show();
            }
        });
    }
}