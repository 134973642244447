import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import * as c from '../core';
import * as m from '../models';

@Component({
    selector: 'login',
    templateUrl: './login.html',
})
export class LoginComponent {
    showPassword = false;
    email: string;
    password: string;
    emailadres: string;
    loginForm: any;
    forgotPassword: boolean = false;
    instructieSpan: boolean = false;

    @Output()
    public userName: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private dispatcher: c.Dispatcher,
        private router: Router,
        private auth: c.AuthenticationService
    ) {
    }

    async login() {
        const request = new m.LoginUserRequest({
            email: this.email,
            password: this.password
        })

        const response = await this.dispatcher.send<m.LoginUserResponse>(request);

        if (response.success) {
            await this.auth.handleAuthentication();

            this.userName.emit(response.userName);
        }

        else confirm("Wachtwoord of e-mail niet gekend in dit systeem.");
    }

    showForgetPasswordForm() {
        this.forgotPassword = true;
    }

    forgetPasswordSubmit() {
        this.forgotPassword = true;
        this.instructieSpan = !this.instructieSpan;
        this.sendNewPassword();
    }

    async sendNewPassword() {
        let request = new m.EmailRequest({
            email: this.emailadres
        });

        await this.dispatcher.send<m.EmailResponse>(request);
    }

    forgetPasswordCancel() {
        this.forgotPassword = false;
        this.instructieSpan = false;
    }
}