import { Component, Input } from '@angular/core';

export enum ModalSize {
    default = '',
    large = 'modal-lg'
}

//See: http://stackoverflow.com/questions/34513558/angular-2-0-and-modal-dialog
@Component({
    selector: 'app-modal',
    templateUrl: './modal.html',
    styles: [`
    .modal {
        background: rgba(0, 0, 0, 0.6);
        overflow-y: auto;
    } `]
})
export class ModalComponent {
    @Input() set modalWidth(value: string) {
        this.modalStyle.width = value;
    };
    @Input() size: ModalSize = ModalSize.large;

    public visible = false;
    public visibleAnimate = false;
    public modalStyle: any = { }

    show(): void {
        this.onShow();
        this.visible = true;
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    hide(): void {
        let hide = this.onHide();
        if (hide !== false) {
            this.visibleAnimate = false;
            setTimeout(() => this.visible = false, 300);
        }
    }

    onHide : () => boolean = () => true;
    onShow : () => void = () => {};

    onContainerClicked(event: MouseEvent): void {
        if ((<HTMLElement>event.target).classList.contains('modal')) {
            this.hide();
        }
    }
}