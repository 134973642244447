import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class UpdatePasswordRequest implements IRequest<UpdatePasswordResponse> {
    constructor(init?: Partial<UpdatePasswordRequest>) {
        Object.assign(this, init);
    }

    id: number;
    newPassword: string;
    confirmPassword: string;
    public $type: string = 'Imversa.Web.RequestHandlers.Security.UserAccounts.UpdatePasswordRequest';
}

export class UpdatePasswordResponse extends ImversaResponse {
    success: boolean;

    constructor(init?: Partial<UpdatePasswordResponse>) {
        super();
        Object.assign(this, init);
    }
} 