import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

declare global {
    interface IRequest<T extends IResponse> {
    }

    interface IResponse {
        validationBag: IValidationBag;
    }

    interface IValidationBag {
        isValid: boolean;
        messages: IValidationMessage[];
      }
    
      interface IValidationMessage {
        type: ValidationType;
        code: string;
        message: string;
      }
    
      enum ValidationType {
        Info,
        Warning,
        Error
      }
}

export class ImversaResponse implements IResponse {
    validationBag: IValidationBag;
  }
  

@Injectable()
export class Dispatcher {
    constructor(
        private httpClient: HttpClient
    ) { }

    // TODO: Make IRequest generic!! (return type is not required then...)
    public async send<TResponse extends IResponse>(request: IRequest<TResponse>): Promise<TResponse> {
      var result = await this.httpClient.post('processor?type=' + request['$type'], request).toPromise();
      return <TResponse>result;
    }
}