import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class LogOutUserRequest implements IRequest<LogOutUserResponse> {
    constructor(init?: Partial<LogOutUserRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.Security.Login.LogOutUserRequest';
}

export class LogOutUserResponse extends ImversaResponse {
    success: boolean;

    constructor(init?: Partial<LogOutUserResponse>) {
        super();
        Object.assign(this, init);
    }
} 