import { Pipe, PipeTransform } from '@angular/core';

/*
 * Return a string representing the Common Enum for the given value.
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | enum
 * Example:
 *   {{ 301 | enum }}
 *   formats to: IBBRole_Administrator
 */

@Pipe({ name: 'enum' })
export class EnumPipe implements PipeTransform {
    transform(value: number): string {
        const enums: any[] = document['enums'];
        const enumValue = enums[value];

        if (enumValue)
            return enumValue;

        if (value == null)
            return '';

        return value.toString();
    }
}