import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortDatePipe',
    pure: false
})
export class SortDatePipe implements PipeTransform {
    transform(items: any[], stopDate: Date, startDate: Date): any {
        // Check if the date string is in the right format like yyyy-mm-dd or yyyy/mm/dd

        if (!items) {
            return items;
        }

        if (startDate && stopDate) {
            var startDateTime = startDate.getTime();
            var stopDateTime = stopDate.getTime();
            if(stopDateTime < startDateTime){
                var temp = stopDateTime;
                stopDateTime = startDateTime;
                startDateTime = temp;
            }
            return items.filter(item => (((new Date(item.timestamp)).getTime() >= startDateTime) && ((new Date(item.timestamp)).getTime() <= stopDateTime)));
        } else if (startDate) {
            return items.filter(item => ((new Date(item.timestamp)).getTime() >= startDate.getTime()));
        } else if (stopDate) {
            return items.filter(item => ((new Date(item.timestamp)).getTime() <= stopDate.getTime()));
        } else {
            return items;
        }
    }
}