<div class="m-content" *ngIf="absence">
    <div class="row">
        <div class="col-lg-12">
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">


                            <h3 class="m-portlet__head-text">
                                Afwezigheid
                            </h3>


                        </div>
                    </div>
                </div>
                <form class="m-form" #form="ngForm">
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">
                            <div class="form-group m-form__group row">
                                <label for="userAccountId" class="col-lg-3 col-form-label"> Medewerker: </label>
                                <div class="w-50">
                                    <select class="form-control" name="userAccountId" [(ngModel)]="absence.userAccountId">
                                        <option *ngFor="let user of users" [value]="user.id"> {{user.firstName}}
                                            {{user.familyName}}
                                    </select>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="Type" class="col-lg-3 col-form-label"> Type: </label>

                                <div class="col-lg-6">
                                    <div class="m-radio-list">
                                        <label class="m-radio" for="inlineRadio1"><input type="radio" name="ziekte" id="inlineRadio1"
                                                [(ngModel)]="absence.type" [value]="type.sickness">Ziekte<span></span></label>
                                        <label class="m-radio" for="inlineRadio2"> <input type="radio" name="vakantie"
                                                id="inlineRadio2" [(ngModel)]="absence.type" [value]="type.vacation">Vakantie<span></span></label>
                                        <label class="m-radio" for="inlineRadio3"><input type="radio" name="overuren"
                                                id="inlineRadio3" [(ngModel)]="absence.type" [value]="type.overtime">Overuren<span></span></label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="startTime" class="col-lg-3 col-form-label"> Startdatum: </label>
                                <app-datetime name="startTime" [(ngModel)]="absence.startTime"></app-datetime>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="endTime" class="col-lg-3 col-form-label"> Einddatum: </label>
                                <app-datetime name="endTime" [(ngModel)]="absence.endTime"></app-datetime>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="remark" class="col-lg-3 col-form-label"> Opmerking: </label>
                                <textarea class="form-control w-50" placeholder="Opmerking" rows="5" name="remark"
                                    [(ngModel)]="absence.remark"></textarea>
                            </div>
                        </div>
                    </div>



                    <div class="m-portlet__foot m-portlet__foot--fit">
                        <div class="m-form__actions m-form__actions--solid">
                            <div class="row">
                                <div class="col-lg-3"></div>
                                <div class="col-lg-6">
                                    <btn-save *ngIf="isNew" [onSave]="handleSaveIsNewAbsence"></btn-save>
                                    <btn-save *ngIf="!isNew" [onSave]="handleSaveIsExistingAbsence"></btn-save>
                                    <button type="button" class="btn btn-default" (click)="returnToOverview()">Terug</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>


            </div>
        </div>
    </div>
</div>