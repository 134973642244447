import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class UpdateResidenceRequest implements IRequest<UpdateResidenceResponse> {
    constructor(init?: Partial<UpdateResidenceRequest>) {
        Object.assign(this, init);
    }

    id: number;
    address: dtos.AddressMiniDto;
    resident: dtos.ResidentReferenceDto;
    contractId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.ClientResidences.UpdateResidenceRequest';
}

export class UpdateResidenceResponse extends ImversaResponse {

    constructor(init?: Partial<UpdateResidenceResponse>) {
        super();
        Object.assign(this, init);
    }
} 