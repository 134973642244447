

export enum Language {
    dutch = 7001,
    french = 7002,
    english = 7003,
}

export namespace Language {
    export function keys(): number[] {
        return [7001, 7002, 7003];
    }
}

document['enums'][7001] = 'common.language_Dutch';
document['enums'][7002] = 'common.language_French';
document['enums'][7003] = 'common.language_English';