

export enum UserStatus {
    employed = 4001,
    notEmployed = 4002,
}

export namespace UserStatus {
    export function keys(): number[] {
        return [4001, 4002];
    }
}

document['enums'][4001] = 'common.userStatus_Employed';
document['enums'][4002] = 'common.userStatus_NotEmployed';