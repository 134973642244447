import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AddressFormComponent } from './address-form.component';

import * as c from '@core';
import * as m from '@models';

@Component({
    selector: 'work-order-modal',
    templateUrl: './work-order-modal.html',
})
export class WorkOrderModalComponent {
    public workOrder: m.WorkOrderReferenceDto;
    public requester: m.IdTextDto;
    public technician: m.IdTextDto;
    public addressId: number;
    public editedAddress: m.AddressMiniDto;

    public showTechniciansSelect: boolean = false;
    public showAddressForm: boolean = false;

    @Input() public requesters: m.IdTextDto[] = [];
    @Input() public technicians: m.IdTextDto[] = [];

    @Output() onChange = new EventEmitter();

    @ViewChild('myWorkOrderModal')
    myWorkOrderModal: c.ModalComponent;

    @ViewChild('addressForm')
    public addressForm: AddressFormComponent;

    constructor(
        private dispatcher: c.Dispatcher
    ) { }

    show(workOrder: m.WorkOrderReferenceDto) {
        this.workOrder = JSON.parse(JSON.stringify(workOrder));
        this.getAddress(workOrder.clientResidenceAddressId);
        this.showTechniciansSelect = false;
        this.showAddressForm = false;

        this.myWorkOrderModal.show();
    }

    async getAddress(id: number) {
        var response = await this.dispatcher.send<m.GetAddressResponse>(new m.GetAddressRequest({ addressId:id }));
        this.editedAddress = response.address;
    }

    async updateWorkOrder() {
        let request = new m.UpdateWorkOrderRequest({
            workOrderId: this.workOrder.id,
            date: this.workOrder.date,
            residentPhone: this.workOrder.clientResidenceResidentPhone,
            description: this.workOrder.description,
            remark: this.workOrder.remark,
            technicianIds: this.workOrder.technicians.map(x => x.id),
            residentFirstName: this.workOrder.clientResidenceResidentFirstName,
            residentLastName: this.workOrder.clientResidenceResidentLastName,
            requesterId: this.workOrder.requesterId,
            addressStreet:  this.editedAddress.street,
            addressBus: this.editedAddress.bus,
            addressNumber: this.editedAddress.number,
            isCancelled: this.workOrder.isCancelled,
            cityId: this.editedAddress.cityId
        });

        await this.dispatcher.send<m.UpdateWorkOrderResponse>(request);
        this.onChange.emit(null);
        this.myWorkOrderModal.hide();
    }

    public editAddress() {
        this.showAddressForm = true;
    }

    editTechnicians() {
        this.showTechniciansSelect = true;
    }

    public newTechnicianId: number = -1;

    addTechnician(id: number) {
        let alreadyRegistered = this.workOrder.technicians.findIndex(x => x.id == id);
        if (alreadyRegistered == -1) {
            let idx = this.technicians.findIndex(x => x.id == id);
            if (idx > -1) {
                this.workOrder.technicians.push(this.technicians[idx]);
            }
        }

        setTimeout(() => { this.newTechnicianId = -1; });
    }

    deleteTechnician(technician: m.IdTextDto){
        var idx = this.workOrder.technicians.indexOf(technician)
        this.workOrder.technicians.splice(idx, 1);
    }
}

