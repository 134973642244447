<app-modal #errormodal>
    <div class="app-modal-header">
        {{ 'client.unknownErrorTitle' | translate }}
    </div>
    <div class="app-modal-body">
        <p class="lead">
            {{ 'client.unknownErrorDescription' | translate }}
        </p>
        <div class="text-right">
            <em>{{ errorMessage }}</em>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-default" (click)="errormodal.hide()">
            {{ 'client.close' | translate }}
        </button>
    </div>
</app-modal>