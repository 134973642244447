<div class="page-single">
    <div class="flex-fill d-flex flex-column justify-content-center">
        <div class="container-tight py-6">
            <div class="text-center mb-4 mt-5">
                <img src="./assets/images/imversa-logo2.png" alt="Imversa">
            </div>
            <form class="card card-md" action="." method="get">
                <div class="card-body">
                    <h2 class="mb-5 text-center">Login als beheerder</h2>
                    <div class="mb-3">
                        <label class="form-label">E-mail</label>
                        <input tabindex="1" type="text" name="email" class="form-control" placeholder="Jouw E-mail adres"
                            autocomplete="off" [(ngModel)]="email">
                    </div>
                    <div class="mb-2">
                        <label class="form-label">
                            Password
                        </label>
                        <div class="input-group input-group-flat">
                            <input tabindex="2" [type]="showPassword ? 'text' : 'password'" name="password" class="form-control"
                                placeholder="Wachtwoord" [(ngModel)]="password">
                            <span class="input-group-text">
                                <a (click)="showPassword = !showPassword;" class="link-secondary" title="Show password" data-toggle="tooltip">
                                    <!-- <fa-icon *ngIf="!showPassword" icon="eye"></fa-icon>
                                    <fa-icon *ngIf="showPassword" icon="eye-slash"></fa-icon> -->
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="form-check">
                            <input type="checkbox" class="form-check-input" />
                            <span class="form-check-label">Remember me on this device</span>
                        </label>
                        <!-- <div class="col m--align-right">
                            <a (click)="showForgetPasswordForm()" id="m_login_forget_password" class="m-link">
                                Wachtwoord vergeten? </a>
                        </div> -->
                    </div>

                    <!-- <div *ngIf="forgotPassword">
                        <div class="m-login__head">
                            <h3 class="m-login__title">Wachtwoord Vergeten?</h3>
                            <div class="m-login__desc">We sturen je een e-mail om je wachtwoord te resetten:</div>
                        </div>
                        <form class="m-login__form m-form">
                            <div class="form-group m-form__group">
                                <input class="form-control m-input" type="text" placeholder="E-mail" name="email"
                                    id="m_email" [(ngModel)]="emailadres">
                            </div>
                            <div class="m-login__form-action">
                                <button (click)="forgetPasswordSubmit()" id="m_login_forget_password_submit" class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air">Vraag
                                    aan</button>
                                <button (click)="forgetPasswordCancel()" id="m_login_forget_password_cancel" class="btn btn-outline-focus m-btn m-btn--pill m-btn--custom">Annuleer</button>
                            </div>
                        </form>

                    </div> -->
                    <div class="form-footer">
                        <button type="submit" (click)="login()" class="btn btn-primary btn-block">Sign in</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>