import * as e from '../../models/enums';

export class UserInfo {
    id: number;
    name: string;
    language: e.Language;
    roles: e.UserRole[];

    constructor(init?: Partial<UserInfo>) {
        Object.assign(this, init);
    }
}