import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetTranslationsRequest implements IRequest<GetTranslationsResponse> {
    constructor(init?: Partial<GetTranslationsRequest>) {
        Object.assign(this, init);
    }

    language: enums.Language;
    public $type: string = 'Imversa.Web.RequestHandlers.System.GetTranslationsRequest';
}

export class GetTranslationsResponse extends ImversaResponse {
    client: { [key: string]: string; };
    common: { [key: string]: string; };

    constructor(init?: Partial<GetTranslationsResponse>) {
        super();
        Object.assign(this, init);
    }
} 