import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetUserInfoRequest implements IRequest<GetUserInfoResponse> {
    constructor(init?: Partial<GetUserInfoRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.Security.Login.GetUserInfoRequest';
}

export class GetUserInfoResponse extends ImversaResponse {
    isLoggedIn: boolean;
    userId: number;
    language: enums.Language;
    roles: enums.UserRole[];
    name: string;

    constructor(init?: Partial<GetUserInfoResponse>) {
        super();
        Object.assign(this, init);
    }
} 