import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GenerateDemoFileRequest implements IRequest<GenerateDemoFileResponse> {
    constructor(init?: Partial<GenerateDemoFileRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.System.GenerateDemoFileRequest';
}

export class GenerateDemoFileResponse extends ImversaResponse {
    key: string;

    constructor(init?: Partial<GenerateDemoFileResponse>) {
        super();
        Object.assign(this, init);
    }
} 