<div class="m-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="m-portlet m-portlet--responsive-mobile">
                
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text m--font-success">Contracten</h3>
                        </div>
                    </div>
                    
                    <div class="m-portlet__head-tools">
                        <div class="btn-group" role="group">
                            <button id="btnGroupDrop1" type="button" class="m-btn btn btn-brand m--font-success dropdown-toggle"
                                data-toggle="dropdown">
                                <fa-icon icon="sort"></fa-icon>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <a class="dropdown-item" (click)="refresh()">Overview </a>
                                <a class="dropdown-item">Type </a>
                                <div class="dropdown-item" (click)="showBedrijf()">
                                    <a class="dropdown-item">Bedrijf</a>
                                </div>
                                <div class="dropdown-item" (click)="showParticulier()">
                                    <a class="dropdown-item">Particulier</a>
                                </div>
                                <div class="dropdown-item" (click)="showAanbesteding()">
                                    <a class="dropdown-item">Aanbesteding</a>
                                </div>
                            </div>
                            <button type="button" class="m-btn btn btn-brand m--font-success" [routerLink]="['/contracts/', 'create']">
                                <fa-icon icon="plus-square"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>


                <ul class="list-group">
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action flex-column" *ngFor="let contract of filteredContracts; index as i"
                        [attr.data-index]="i" (click)="toggleContractField(contract); highlightContracts[i]=!highlightContracts[i]"
                        [class.active]="highlightContracts[i]">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1" [routerLink]="['/contracts/detail/', contract.id, 'edit']">
                                {{contract.reference}}</h5>
                            <small class="text-muted align-self-start" *ngIf="contract.type == 1">Bedrijf</small>
                            <small class="text-muted align-self-start" *ngIf="contract.type == 2">Particulier</small>
                            <small class="text-muted align-self-start" *ngIf="contract.type == 3">Aanbesteding</small>
                        </div>

                        <p>{{contract.residences.length}} residenties, {{contract.tasks.length}} taken</p>

                        <small>{{contract.address?.cityName }}</small>
                    </a>
                </ul>
            </div>
        </div>
    </div>
</div>