<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title">Sandbox</h3>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="panel panel-default">
        <div class="panel-body">
            <h3>TeamLeader</h3>

            <div class="bd-example">
                <btn-save [onSave]="synchronizeTeamLeader" title="client.synchronizeTeamLeader"></btn-save>
            </div>
            <div class="highlight"></div>

            <h3>Save Button</h3>

            <div class="bd-example">
                <btn-save [onSave]="handleSave"></btn-save>
            </div>
            <div class="highlight">
                <pre>{{saveString}}</pre>
            </div>

            <h3>Delete Button</h3>

            <div class="bd-example">
                <btn-delete [onDelete]="handleDelete"></btn-delete>
            </div>
            <div class="highlight">
                <pre>{{deleteString}}</pre>
            </div>

            <h3>Add Button</h3>

            <div class="bd-example">
                <btn-add [addToepassing]="handleAdd"></btn-add>
            </div>
            <div class="highlight">
                <pre>{{addString}}</pre>
            </div>

            <hr />

            <h3>Ping</h3>

            <div class="bd-example">
                <div class="form-group">
                    <button (click)="ping()" class="btn btn-primary">
                        Perform ping request
                    </button>
                </div>
            </div>
            <div class="highlight">
                <pre>Response: {{pingResponse | json}}</pre>
            </div>

            <h3>Email</h3>

            <div class="bd-example">
                <div class="form-group">
                    <button (click)="email()" class="btn btn-primary">
                        Send e-mail
                    </button>
                </div>
            </div>
            <div class="highlight">
                <pre>Response: {{emailResponse | json}}</pre>
            </div>

            <h3>Modal</h3>

            <div class="bd-example">
                <button class="btn btn-default" (click)="myModal.show()">Open modal</button>

                <app-modal #myModal>
                    <div class="app-modal-header">
                        <h5 class="modal-title">Modal title</h5>
                    </div>
                    <div class="app-modal-body">
                        <p>Modal body text goes here.</p>
                    </div>
                    <div class="app-modal-footer">
                        <button type="button" class="btn btn-default" (click)="myModal.hide()">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                </app-modal>
            </div>
            <div class="highlight">
                <pre>{{modalString}}</pre>
            </div>

            <h3>Datetime</h3>

            <div class="bd-example">
                <app-datetime name="startDate" [(ngModel)]="startDate" [disabled]="disableDateTime"></app-datetime>
                <br />

                <label>{{startDate}}</label>

                <br />
                <button class="btn btn-default" (click)="resetStartDate()">Reset date</button>

                <br />
                <br />
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="disableDateTime" id="disableDateTime"
                        name="disableDatetime">
                    <label class="form-check-label" for="disableDateTime">
                        Disable datepicker
                    </label>
                </div>
            </div>
            <div class="highlight">
                <pre>{{datetimeString}}</pre>
            </div>

            <h3>Exceptions</h3>

            <div class="bd-example">
                <btn-save [onSave]="throwServerException" title="client.throwServerException"></btn-save>
                <button type="button" class="btn btn-default" (click)="throwClientException()">
                    {{ 'client.throwClientException' | translate }}
                </button>
            </div>
            <div class="highlight"></div>

            <h3>File Storage</h3>

            <div class="bd-example">
                <p>Click to generate file, this sends a "GenerateDemoFileRequest", which returns a file-key.</p>

                <btn-save [onSave]="generateTempFile" title="client.generateTempFile"></btn-save>
            </div>
            <div class="highlight">
                <pre>{{demoFile | json}}</pre>
            </div>

            <div class="bd-example">
                <p>You can then provide a hyperlink to download the file.</p>

                <a *ngIf="demoFile" href="/download-file?key={{demoFile.key}}" target="_self">download file</a>
            </div>
            <div class="highlight"></div>
            
            <div class="bd-example">
                <p>You can also upload a file.</p>

                <file-upload (fileUploaded)="onFileUploaded($event)"></file-upload>

                <div *ngIf="uploadedFile">
                    <a href="/download-file?key={{uploadedFile.key}}" target="_self">{{uploadedFile.fileName}}</a>
                </div>
            </div>
            <div class="highlight">
                <pre>{{uploadedFile | json}}</pre>
            </div>
        </div>
    </div>
</div>