import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class CreateUserRequest implements IRequest<CreateUserResponse> {
    constructor(init?: Partial<CreateUserRequest>) {
        Object.assign(this, init);
    }

    firstName: string;
    familyName: string;
    email: string;
    shortNameTech: string;
    shortNamePlan: string;
    status: enums.UserStatus;
    roles: enums.UserRole[];
    password: string;
    public $type: string = 'Imversa.Web.RequestHandlers.Security.UserAccounts.CreateUserRequest';
}

export class CreateUserResponse extends ImversaResponse {
    user: dtos.UserAccountDto;

    constructor(init?: Partial<CreateUserResponse>) {
        super();
        Object.assign(this, init);
    }
} 