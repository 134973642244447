import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkOrderModalTechnicianComponent } from './components/work-order-modal-technician.component';

import * as c from '@core';
import * as m from '@models';

@Component({
    templateUrl: './technician-overview.html',
})
export class TechnicianOverviewComponent implements OnInit {
    @ViewChild('pagination')
    public pagination: c.PaginationComponent;

    @ViewChild('editWorkOrderModalTech')
    public editWorkOrderModalTech: WorkOrderModalTechnicianComponent;

    @ViewChild('closeBox')
    public closeBox: c.ModalComponent;

    public workOrdersToday: m.WorkOrderReferenceDto[] = [];
    public workOrderToClose: m.WorkOrderReferenceDto;
    public nameClient: string;
    public dateToday: any;

    public currentPage: number = 1;
    public pageSize: number = 10;
    public total: number;

    constructor(private dispatcher: c.Dispatcher) { }

    ngOnInit(): void {
        this.refresh();
        this.dateToday = new Date()
    }

    refresh() {
        this.getWorkordersByDate();
    }

    public openWorkOrderTechnicianEditModal(workorder: m.WorkOrderReferenceDto) {
        this.editWorkOrderModalTech.show(workorder);
    }

    async getWorkordersByDate() {
        const response = await this.dispatcher.send<m.GetWorkOrdersByTechnicianResponse>(new m.GetWorkOrdersByTechnicianRequest());
        this.workOrdersToday = response.currentWorkOrders;
    }

    public pageChanged(event: number) {
        this.currentPage = event;
        this.refresh();
    }

    openCloseBoxWorkOrder(workorderId: number) {
        this.workOrderToClose = this.workOrdersToday.filter(x => x.id == workorderId)[0];
        this.nameClient = this.workOrderToClose.clientResidenceResidentFirstName + " " + this.workOrderToClose.clientResidenceResidentLastName;
        this.closeBox.show();
    }

    async closeWorkOrder() {
        await this.dispatcher.send<m.CloseWorkOrderByTechnicianResponse>(new m.CloseWorkOrderByTechnicianRequest({ workOrderId: this.workOrderToClose.id }));
        this.closeBox.hide();
        this.refresh();
    }

    cancelCloseWorkOrder() {
        this.workOrderToClose = null;
        this.nameClient = '';
        this.closeBox.hide();
    }
}
