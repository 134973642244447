import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'

export class UserLoginDto extends EntityReferenceDto {

    public email: string;
    public password: string;

    constructor(init?: Partial<UserLoginDto>) {
        super(init);
        Object.assign(this, init);
    }
}