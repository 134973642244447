import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetDistinctResidenceRequest implements IRequest<GetDistinctResidenceResponse> {
    constructor(init?: Partial<GetDistinctResidenceRequest>) {
        Object.assign(this, init);
    }

    id: number;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.ClientResidences.GetDistinctResidenceRequest';
}

export class GetDistinctResidenceResponse extends ImversaResponse {
    residence: dtos.ClientResidenceDto;

    constructor(init?: Partial<GetDistinctResidenceResponse>) {
        super();
        Object.assign(this, init);
    }
} 