import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as m from '../../models';
import { Dispatcher } from '../../core';


@Component({
    selector: 'clientresidence-detail',
    templateUrl: './clientresidencedetail.html'
})
export class ClientresidenceDetailComponent implements OnInit, OnDestroy {
    public cr: m.ClientResidenceDto = null;
    private sub: any;
    public contractId: number;

    ngOnInit() {
        this.refresh();
    }

    ngOnDestroy() {
        this.sub.next();
        this.sub.complete();
    }

    constructor(
        private dispatcher: Dispatcher,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    refresh() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (!params) return;
            let crId = +params['residenceId']; 
            this.contractId = +params['id'];
            this.openResidence(crId)
        });
    }

    async openResidence(crId: number) {
        let response = await this.dispatcher.send<m.GetDistinctResidenceResponse>(new m.GetDistinctResidenceRequest({ id: crId }))
        this.cr = response.residence;
    }

    public handleSaveIsNotNew = async () => {
        var adres = new m.AddressMiniDto({
            id: this.cr.address.id,
            street: this.cr.address.street,
            number: this.cr.address.number,
            bus: this.cr.address.bus,
            cityPostalCode: this.cr.address.cityPostalCode,
            cityName: this.cr.address.cityName
        });

        var resident = new m.ResidentReferenceDto({
            id: this.cr.resident.id,
            firstName: this.cr.resident.firstName,
            lastName: this.cr.resident.lastName
        })

        this.contractId = this.cr.contract.id;

        const request = new m.UpdateResidenceRequest({
            id: this.cr.id,
            address: adres,
            resident: resident,
            contractId: this.contractId
        })

        await this.dispatcher.send<m.UpdateResidenceResponse>(request);
        this.returnToDetail();
    }

    returnToDetail() {
        this.router.navigate(['contracts/detail/' + this.contractId + '/edit']);
    }

}

