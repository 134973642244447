<app-modal #myWorkOrderModalTechnician>
    <div class="app-modal-header">
        <h5 class="modal-title">Werkbon</h5>
    </div>
    <div class="app-modal-body">
        <div class="card mb-3">
            <div class="card-body" *ngIf="workOrder">
                <dl class="mb-3 row d-flex align-items-center">
                    <dt class="col-3 d-flex align-items-center">Naam
                        klant</dt>
                    <dd class="col d-flex align-items-center">
                        {{workOrder.clientResidenceResidentFirstName}} {{workOrder.clientResidenceResidentLastName}}
                    </dd>
                </dl>
                <dl class="mb-3 row d-flex align-items-center">
                    <dt class="col-3">Adres</dt>
                    <dd class="col">{{workOrder.clientResidenceAddressLine}}</dd>
                </dl>
                <dl class="mb-3 row">
                    <dt class="col-3">Datum</dt>
                    <dd class="col">{{workOrder.date | date : 'fullDate'}}</dd>
                </dl>
                <dl class="mb-3 row">
                    <dt class="col-3">Omschrijving</dt>
                    <dd class="col">{{ workOrder.description}}</dd>
                </dl>
                <dl class="mb-3 row">
                    <dt class="col-3">Afgesproken met</dt>
                    <dd class="col">{{workOrder.requesterFirstName}}</dd>
                </dl>
                <dl class="mb-3 row">
                    <dt class="col-3">Uitvoerders</dt>
                    <dd class="col">
                        <div class="col-6 mb-3" *ngIf="workOrder.technicians.length > 0">
                            <div *ngFor="let t of workOrder.technicians">
                                <div class="row align-items-center">
                                    <div class="col text-truncate">{{t.text}}</div>
                                </div>
                            </div>
                        </div>
                    </dd>
                </dl>
                <dl class="mb-3 row">
                    <dt class="col-3">Opmerking</dt>
                    <dd class="col">
                        {{workOrder.remark}}
                    </dd>
                </dl>
                <form  *ngIf="workOrderTechnicianEdit">
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="performedWork">Uitgevoerde werken</label>
                        <div class="col">
                            <textarea type="text" rows="4" class="form-control" name="performedWork" id="performedWork"
                                [(ngModel)]="workOrderTechnicianEdit.performedWork"></textarea>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label" for="deliveredMaterial">Geleverd
                            materiaal</label>
                        <div class="col">
                            <textarea type="text" rows="2" class="form-control" name="deliveredMaterial"
                                id="deliveredMaterial" [(ngModel)]="workOrderTechnicianEdit.deliveredMaterial"></textarea>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label"
                            for="installationData">Installatiegegevens</label>
                        <div class="col">
                            <textarea type="text" rows="4" class="form-control" name="installationData"
                                id="installationData" [(ngModel)]="workOrderTechnicianEdit.installationData"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="form-label col-3 col-form-label pt-0" for="actions">Opvolging</label>
                        <div class="col">
                            <label class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" [(ngModel)]="furtherActionNeeded"
                                    name="actions" [value]="true">
                                <span class="form-check-label">Ja</span>
                            </label>
                            <label class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" [(ngModel)]="furtherActionNeeded"
                                    name="actions" [value]="false">
                                <span class="form-check-label">Nee</span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group mb-3 row" *ngIf="furtherActionNeeded">
                        <label class="form-label col-3 col-form-label"
                            for="furtherActionDescription">Omschrijving</label>
                        <div class="col">
                            <textarea type="text" rows="4" class="form-control" name="furtherActionDescription"
                                id="furtherActionDescription" [(ngModel)]="workOrderTechnicianEdit.furtherActionDescription"></textarea>
                        </div>
                    </div>
                    <ul *ngIf="hasError" class="text-danger">
                        <li *ngFor="let m of bag.messages">{{m.message}}</li>
                    </ul>
                </form>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <div class="btn-list flex-nowrap me-auto">
            <button type="button" class="btn btn-default me-1" (click)="myWorkOrderModalTechnician.hide();">
                Annuleren
            </button>
            <button type="button" class="btn btn-primary" (click)="updateWorkOrderTechnician()">
                Opslaan
            </button>
        </div>
    </div>
</app-modal>