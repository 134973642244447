import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class CreateClientResidenceHistoryRequest implements IRequest<CreateClientResidenceHistoryResponse> {
    constructor(init?: Partial<CreateClientResidenceHistoryRequest>) {
        Object.assign(this, init);
    }

    firstName: string;
    lastName: string;
    clientResidenceId: number;
    remark: string;
    date: Date;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.ClientResidenceHistory.CreateClientResidenceHistoryRequest';
}

export class CreateClientResidenceHistoryResponse extends ImversaResponse {

    constructor(init?: Partial<CreateClientResidenceHistoryResponse>) {
        super();
        Object.assign(this, init);
    }
} 