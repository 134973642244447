import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class ExcelToWordRequest implements IRequest<ExcelToWordResponse> {
    constructor(init?: Partial<ExcelToWordRequest>) {
        Object.assign(this, init);
    }

    keyString: string;
    public $type: string = 'Imversa.Web.RequestHandlers.Excel.ExcelToWordRequest';
}

export class ExcelToWordResponse extends ImversaResponse {
    amountParsed: number;
    linesWithIssues: dtos.ImportExcelLineDto[];
    usersToAdd: string[];
    key: string;

    constructor(init?: Partial<ExcelToWordResponse>) {
        super();
        Object.assign(this, init);
    }
} 