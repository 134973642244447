import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetWorkOrdersRequest implements IRequest<GetWorkOrdersResponse> {
    constructor(init?: Partial<GetWorkOrdersRequest>) {
        Object.assign(this, init);
    }

    fetchInitialData: boolean;
    currentPage: number;
    pageSize: number;
    searchInput: string;
    dateInput: Date;
    requesterId: number;
    technicianId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.WorkOrders.GetWorkOrdersRequest';
}

export class GetWorkOrdersResponse extends ImversaResponse {
    workOrders: dtos.WorkOrderReferenceDto[];
    requesters: dtos.IdTextDto[];
    technicians: dtos.IdTextDto[];
    count: number;

    constructor(init?: Partial<GetWorkOrdersResponse>) {
        super();
        Object.assign(this, init);
    }
} 