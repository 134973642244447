import * as enums from '../enums';
import { EntityDto } from './EntityDto'
import { AddressMiniDto } from './AddressMiniDto';
import { ContractReferenceDto } from './ContractReferenceDto';
import { ResidentReferenceDto } from './ResidentReferenceDto';

export class ClientResidenceDto extends EntityDto {

    public key: string;
    public address: AddressMiniDto;
    public resident: ResidentReferenceDto;
    public residentIsOwner: boolean;
    public contract: ContractReferenceDto;

    constructor(init?: Partial<ClientResidenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}