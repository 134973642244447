import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class LoginUserRequest implements IRequest<LoginUserResponse> {
    constructor(init?: Partial<LoginUserRequest>) {
        Object.assign(this, init);
    }

    email: string;
    password: string;
    public $type: string = 'Imversa.Web.RequestHandlers.Security.Login.LoginUserRequest';
}

export class LoginUserResponse extends ImversaResponse {
    success: boolean;
    userId: number;
    userName: string;
    name: string;
    roles: enums.UserRole[];
    email: string;
    password: string;
    reason: string;

    constructor(init?: Partial<LoginUserResponse>) {
        super();
        Object.assign(this, init);
    }
} 