import * as enums from '../enums';
import { EntityDto } from './EntityDto'

export class AbsenceDto extends EntityDto {

    public userAccountId: number;
    public userAccountFirstName: string;
    public userAccountFamilyName: string;
    public userAccountShortName: string;
    public startTime: Date;
    public endTime: Date;
    public type: enums.AbsenceType;
    public remark: string;

    constructor(init?: Partial<AbsenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}