import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class CreateContractRequest implements IRequest<CreateContractResponse> {
    constructor(init?: Partial<CreateContractRequest>) {
        Object.assign(this, init);
    }

    type: enums.ContractType;
    reference: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactAddress: dtos.AddressMiniDto;
    startDate: Date;
    endDate: Date;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.Contracts.CreateContractRequest';
}

export class CreateContractResponse extends ImversaResponse {
    contract: dtos.ContractDto;

    constructor(init?: Partial<CreateContractResponse>) {
        super();
        Object.assign(this, init);
    }
} 