import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class CreateContactRequest implements IRequest<CreateContactResponse> {
    constructor(init?: Partial<CreateContactRequest>) {
        Object.assign(this, init);
    }

    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isOwner: boolean;
    street: string;
    number: string;
    bus: string;
    cityId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Home.CreateContactRequest';
}

export class CreateContactResponse extends ImversaResponse {
    clientResidence: dtos.ClientResidenceReferenceDto;
    success: boolean;

    constructor(init?: Partial<CreateContactResponse>) {
        super();
        Object.assign(this, init);
    }
} 