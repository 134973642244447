import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class PlanningRequest implements IRequest<PlanningResponse> {
    constructor(init?: Partial<PlanningRequest>) {
        Object.assign(this, init);
    }

    startDate: Date;
    public $type: string = 'Imversa.Web.RequestHandlers.Home.PlanningRequest';
}

export class PlanningResponse extends ImversaResponse {
    datedWorkOrders: dtos.WorkOrderReferenceDto[];
    technicians: dtos.UserAccountReferenceDto[];
    absentees: dtos.AbsenceDto[];
    dates: Date[];

    constructor(init?: Partial<PlanningResponse>) {
        super();
        Object.assign(this, init);
    }
} 