import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class UpdateAbsenceRequest implements IRequest<UpdateAbsenceResponse> {
    constructor(init?: Partial<UpdateAbsenceRequest>) {
        Object.assign(this, init);
    }

    id: number;
    userId: number;
    type: enums.AbsenceType;
    startTime: Date;
    endTime: Date;
    remark: string;
    public $type: string = 'Imversa.Web.RequestHandlers.HR.Absences.UpdateAbsenceRequest';
}

export class UpdateAbsenceResponse extends ImversaResponse {

    constructor(init?: Partial<UpdateAbsenceResponse>) {
        super();
        Object.assign(this, init);
    }
} 