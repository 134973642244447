import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import * as c from '@core';
import * as m from '@models';

@Component({
    selector: 'header',
    templateUrl: './header.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public userInfo: c.UserInfo;

    public rol = m.UserRole;

    public isTechnician = false;
    public isAdmin = false;
    public isPlanner = false;

    constructor(
        public auth: c.AuthenticationService
    ) {
    }

    ngOnInit() {
        this.auth
            .userInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(u => {
                this.userInfo = u;
                if (this.userInfo != null) {
                    if (this.userInfo.roles.find(x => x == this.rol.administrator)) {
                        this.isAdmin = true;
                    }
                    if (this.userInfo.roles.find(x => x == this.rol.technician)) {
                        this.isTechnician = true;
                    }
                    if (this.userInfo.roles.find(x => x == this.rol.planner)) {
                        this.isPlanner = true;
                    }
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    logout() {
        this.auth.logout();
    }
}