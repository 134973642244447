import * as enums from '../enums';


export class AddressMiniInputDto  {

    public street: string;
    public number: number;
    public bus: string;
    public cityId: number;
    public cityPostalCode: string;
    public cityName: string;
    public residentName: string;
    public residentTelephone: string;

    constructor(init?: Partial<AddressMiniInputDto>) {
        Object.assign(this, init);
    }
}