import { RouterModule } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CoreModule } from '../core';
import { LoginComponent } from './login.component';
import { PassWordComponent } from './password.component';

import * as routes from './login.routes';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CoreModule,
        RouterModule,
        routes.routing
    ],
    declarations: [
        PassWordComponent,
        LoginComponent
    ],
    exports: [
        RouterModule
    ]
})
export class LoginModule { }