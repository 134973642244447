import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'

export class WorkOrderLabelReferenceDto extends EntityReferenceDto {

    public labelName: string;
    public labelColor: string;

    constructor(init?: Partial<WorkOrderLabelReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}