import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as c from '../../core';
import * as m from '../../models';

@Component({
    selector: 'absence-detail',
    templateUrl: './absencedetail.html',
})
export class AbsencedetailComponent implements OnInit {
    private sub: any;
    public absence: m.AbsenceDto;
    public isNew: boolean;
    public type = m.AbsenceType;

    public users: m.UserAccountReferenceDto[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dispatcher: c.Dispatcher) {
    }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (!params) return;
            let absenceId = +params['id']; // (+) converts string 'id' to a number
            this.isNew = isNaN(absenceId);

            if (!this.isNew) {
                this.openAbsence(absenceId)
            }
            else {
                this.absence = new m.AbsenceDto({
                    userAccountId: 0,
                    startTime: new Date,
                    endTime: new Date,
                    remark: ''
                });

                this.getUsers();
            }
        });
    }

    ngOnDestroy() {
        this.sub.next();
        this.sub.complete();
    }

    public async openAbsence(absenceId: number) {
        let request = new m.GetDistinctAbsenceRequest({ id: absenceId });
        let response = await this.dispatcher.send<m.GetDistinctAbsenceResponse>(request);

        this.absence = response.absence;
        this.users = response.users;
    }

    async getUsers() {
        let response = await this.dispatcher.send<m.GetUsersResponse>(new m.GetUsersRequest);
        this.users = response.users;
    }

    public handleSaveIsNewAbsence = async () => {
        const request = new m.CreateAbsenceRequest({
            userId: this.absence.userAccountId,
            type: this.absence.type,
            startTime: this.absence.startTime,
            endTime: this.absence.endTime,
            remark: this.absence.remark
        })

        await this.dispatcher.send<m.CreateAbsenceResponse>(request);
        this.returnToOverview();
    }

    public handleSaveIsExistingAbsence = async () => {
        const request = new m.UpdateAbsenceRequest({
            id: this.absence.id,
            userId: this.absence.userAccountId,
            type: this.absence.type,
            startTime: this.absence.startTime,
            endTime: this.absence.endTime,
            remark: this.absence.remark
        })

        await this.dispatcher.send<m.UpdateAbsenceResponse>(request);
        this.returnToOverview();
    }

    returnToOverview() {
        this.router.navigate(['absences']);
    }

}


