import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dispatcher } from '@core';

import * as m from '@models';

@Component({
    selector: 'address-form',
    templateUrl: 'address-form.html'
})

export class AddressFormComponent implements OnInit {
    @Input()
    public address: m.AddressMiniDto;

    public street: string;
    public number: string;
    public bus: string;
    public cities: m.CityDto[] = [];

    constructor(
        private dispatcher: Dispatcher
    ) {
    }

    async ngOnInit() {
        await this.getCities();
    }

    onChangeCity(id: number) {
        this.address.cityId = id;
    }

    private async getCities() {
        var response = await this.dispatcher.send<m.GetCitiesResponse>(new m.GetCitiesRequest());
        this.cities = response.cities;
    }
}