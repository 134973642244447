import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'pagination',
    templateUrl: 'pagination.html'
})
export class PaginationComponent {
    private _total: number = 0;
    private _pageSize: number = 0;

    public lastPage: number;

    public disableNext: boolean = false;
    public disablePrev: boolean = true;
    public enableFirst = false;
    public enableFirstDots = false;
    public enableLast = false;
    public enableLastDots = false;
    public pages: number[] = [];

    @Input() public currentPage: number;
    @Input() set total(newValue: number) {
        this._total = newValue;
        this.setNumberOfPages();
    }
    @Input() set pageSize(newValue: number) {
        this._pageSize = newValue;
        this.setNumberOfPages();
    }

    @Output() currentPageChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() triggerRefresh: EventEmitter<boolean> = new EventEmitter();

    private refreshPages() {
        let total = this.lastPage;
        if (total == null || total == 0 || isNaN(total)) return [];

        let overflow = total > 5;
        let size = overflow ? 5 : total;
        let startIdx = overflow
            ? this.findStartIdx()
            : 1;

        this.pages = [...Array(size)].map((_, i) => i + startIdx);

        if (this.lastPage > 5) {
            this.enableFirst = ![1, 2, 3].includes(this.currentPage);
            this.enableLast = ![this.lastPage, this.lastPage - 1, this.lastPage - 2].includes(this.currentPage);
        }
        else {
            this.enableFirst = false;
            this.enableLast = false;
        }

        if (this.lastPage > 6) {
            this.enableFirstDots = ![1, 2, 3, 4].includes(this.currentPage);
            this.enableLastDots = ![this.lastPage, this.lastPage - 1, this.lastPage - 2, this.lastPage - 3].includes(this.currentPage);
        } else {
            this.enableFirstDots = false;
            this.enableLastDots = false;
        }
    }

    private findStartIdx() {
        if (this.currentPage - 2 < 1) {
            return 1;
        }

        var top = Math.min(this.currentPage + 2, this.lastPage);
        return top - 4;
    }

    private setNumberOfPages() {
        if (this._total == 0 || this._pageSize == 0) {
            this.pages = [];
        } else {
            this.lastPage = Math.ceil(this._total / this._pageSize);
            this.refreshPages();
        }
    }

    setCurrentPage(index: number) {
        this.currentPage = index;
        this.checkNextOrPrev();
        this.refreshPages();

        this.currentPageChange.emit(this.currentPage);
    }

    prev() {
        if (!this.disablePrev) {
            this.currentPage = this.currentPage - 1;
            this.currentPageChange.emit(this.currentPage);
            this.checkNextOrPrev();
            this.refreshPages();
        }
        else {
            this.currentPageChange.emit(this.currentPage);
        }
    }

    next() {
        if (!this.disableNext) {
            this.currentPage = this.currentPage + 1;
            this.currentPageChange.emit(this.currentPage);
            this.checkNextOrPrev();
            this.refreshPages();
        }
        else {
            this.currentPageChange.emit(this.currentPage);
        }
    }

    private checkNextOrPrev() {
        this.disablePrev = this.currentPage <= 1;
        this.disableNext = this.currentPage == this.lastPage;
    }
}