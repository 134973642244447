import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fas from '@fortawesome/free-solid-svg-icons';

library.add(far.far);
library.add(fas.fas);

import { Dispatcher, LazyLoaderInterceptor, LogOutInterceptor } from './http';
import { AuthenticationService } from './auth';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { ExceptionModalComponent, ExceptionService } from './exceptions';
import { Check } from './check/check.component';
import { FileInfo, FileGenerate } from './files';

import * as contracts from './contracts';
import * as translate from './translate';
import * as buttons from './buttons';
import * as inputs from './inputs';
import * as files from './files';
import * as sort from './sort';
import * as pipes from './pipes';
import * as pag from './pagination';

export * from './http';
export * from './contracts';
export * from './auth';
export * from './buttons';
export * from './sort';
export * from './inputs';
export * from './translate';
export * from './files';
export * from './pagination';

export {
    FileInfo,
    FileGenerate,
    ModalComponent,
};

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        FontAwesomeModule
    ],
    exports: [
        AlertComponent,
        Check,
        ModalComponent,

        buttons.BtnSave,
        buttons.BtnDelete,
        buttons.BtnUndo,
        buttons.BtnAdd,
        inputs.DatetimeComponent,
        inputs.NumberInput,
        contracts.EnumPipe,
        ExceptionModalComponent,
        files.FileUpload,
        files.BytesPipe,
        files.FileGenerate,
        pipes.PropertyFilterPipe,
        pipes.LazyFilterPipe,
        pipes.SortDatePipe,
        pipes.KeysPipe,
        sort.SortModalComponent,
        translate.TranslatePipe,
        pag.PaginationComponent,
    ],
    declarations: [
        AlertComponent,
        buttons.BtnSave,
        buttons.BtnDelete,
        buttons.BtnUndo,
        buttons.BtnAdd,
        inputs.DatetimeComponent,
        inputs.NumberInput,
        contracts.EnumPipe,
        Check,
        ExceptionModalComponent,
        files.FileUpload,
        files.BytesPipe,
        files.FileGenerate,
        ModalComponent,
        pipes.PropertyFilterPipe,
        pipes.LazyFilterPipe,
        pipes.SortDatePipe,
        pipes.KeysPipe,
        sort.SortModalComponent,
        translate.TranslatePipe,
        pag.PaginationComponent,
    ],
    providers: [
        AuthenticationService,
        Dispatcher,
        translate.TranslateService,
        translate.TranslatePipe,
        contracts.EnumPipe,

        ExceptionService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LazyLoaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogOutInterceptor,
            multi: true
        }
    ]
})
export class CoreModule { }
