import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetDistinctContractRequest implements IRequest<GetDistinctContractResponse> {
    constructor(init?: Partial<GetDistinctContractRequest>) {
        Object.assign(this, init);
    }

    id: number;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.Contracts.GetDistinctContractRequest';
}

export class GetDistinctContractResponse extends ImversaResponse {
    contract: dtos.ContractDto;

    constructor(init?: Partial<GetDistinctContractResponse>) {
        super();
        Object.assign(this, init);
    }
} 