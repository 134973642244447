import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main.component';

import * as absences from './absences';
import * as addresses from './addresses';
import * as users from './users';
import * as contracts from './contracts';
import * as residences from './clientresidence';
import * as residents from './residents';
import * as sandbox from './sandbox';
import * as wo from './work-order';

export const routes: Routes = [
    {
        path: 'main',
        component: MainComponent,
        children: [
            {
                path: '',
                redirectTo: 'work-order',
                pathMatch: 'full'
            },
            {
                path: 'absences',
                children: absences.routes
            },
            {
                path: 'addresses',
                children: addresses.routes
            },
            {
                path: 'users',
                children: users.routes
            },
            {
                path: 'contracts',
                children: contracts.routes
            },
            {
                path: 'residents',
                children: residents.routes
            },
            {
                path: 'sandbox',
                children: sandbox.routes
            },
            {
                path: 'residences',
                children: residences.routes
            },
            {
                path: 'work-order',
                children: wo.routes
            }
        ]
    }
];

export const routing = RouterModule.forRoot(routes);