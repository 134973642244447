import * as enums from '../enums';
import { EntityDto } from './EntityDto'

export class AddressMiniDto extends EntityDto {

    public street: string;
    public number: string;
    public bus: string;
    public cityId: number;
    public cityPostalCode: string;
    public cityName: string;

    constructor(init?: Partial<AddressMiniDto>) {
        super(init);
        Object.assign(this, init);
    }
}