import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class DeleteUserRequest implements IRequest<DeleteUserResponse> {
    constructor(init?: Partial<DeleteUserRequest>) {
        Object.assign(this, init);
    }

    id: number;
    restore: boolean;
    public $type: string = 'Imversa.Web.RequestHandlers.Security.UserAccounts.DeleteUserRequest';
}

export class DeleteUserResponse extends ImversaResponse {

    constructor(init?: Partial<DeleteUserResponse>) {
        super();
        Object.assign(this, init);
    }
} 