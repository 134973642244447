import { Component, OnInit } from '@angular/core';
import { Dispatcher } from '../../core';

import * as m from '../../models';

@Component({
  selector: 'contractoverview',
  templateUrl: './contractoverview.html'
})
export class ContractoverviewComponent implements OnInit {
  public contracts: m.ContractDto[];

  public _listFilter: string;
  public filteredContracts: m.ContractDto[];
  public clickedContracts: m.ContractDto[] = [];
  highlightContracts: Array<boolean> = [];

  public type: m.ContractType;

  constructor(private dispatcher: Dispatcher) { }

  ngOnInit() {
    this.refresh();
  }

  async refresh() {
    this.emptyAllArays();

    let response = await this.dispatcher.send<m.GetContractsResponse>(new m.GetContractsRequest());
    this.contracts = response.contracts;
    this.filteredContracts = response.contracts;
  }

  get listFilter(): string {
    return this._listFilter;
  }

  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredContracts = this.listFilter ? this.performFilter(this.listFilter) : this.contracts;
  }

  performFilter(filterBy: string): m.ContractDto[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.contracts.filter((cr: m.ContractDto) => cr.address.cityName.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  getSelectedContractId(): number {
    return this.clickedContracts.length > 0 ? this.clickedContracts[0].id : 0;
  }

  toggleContractField(contract: m.ContractDto) {
    const idx = this.clickedContracts.indexOf(contract);
    if (idx === -1) {
      this.clickedContracts.push(contract);
    } else {
      this.clickedContracts.splice(idx, 1);
    }
  }

  showBedrijf() {
    this.emptyAllArays();
    this.filteredContracts = this.contracts.filter((contract: m.ContractDto) => contract.type === m.ContractType.company)
  }

  showParticulier() {
    this.emptyAllArays();
    this.filteredContracts = this.contracts.filter((contract: m.ContractDto) => contract.type === m.ContractType.private)
  }

  showAanbesteding() {
    this.emptyAllArays();
    this.filteredContracts = this.contracts.filter((contract: m.ContractDto) => contract.type === m.ContractType.tender)
  }

  emptyAllArays(): void {
    this.clickedContracts = [];
    this.highlightContracts = [];
  }

}
