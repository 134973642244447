import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetContractsRequest implements IRequest<GetContractsResponse> {
    constructor(init?: Partial<GetContractsRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.Contracts.GetContractsRequest';
}

export class GetContractsResponse extends ImversaResponse {
    contracts: dtos.ContractDto[];

    constructor(init?: Partial<GetContractsResponse>) {
        super();
        Object.assign(this, init);
    }
} 