import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class DeleteAbsenceRequest implements IRequest<DeleteAbsenceResponse> {
    constructor(init?: Partial<DeleteAbsenceRequest>) {
        Object.assign(this, init);
    }

    id: number;
    public $type: string = 'Imversa.Web.RequestHandlers.HR.Absences.DeleteAbsenceRequest';
}

export class DeleteAbsenceResponse extends ImversaResponse {

    constructor(init?: Partial<DeleteAbsenceResponse>) {
        super();
        Object.assign(this, init);
    }
} 