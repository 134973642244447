import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lazyFilter',
    pure: false
})
@Injectable()
export class LazyFilterPipe implements PipeTransform {
    transform(items: any[], value: any, deep: boolean): any[] {
        if (!items) return items;
        if (!value) return items;

        if (deep == null || deep == undefined) {
            deep = false;
        }

        return items.filter((el: any) => {
            return this.compare(el, value, deep);
        });
    }

    private compare(element: any, filter: any, deep: boolean) : boolean {
        let areEqual: boolean = true;

        const filterKeys = Object.keys(filter);

        for (let i = 0; i < filterKeys.length; i++) {
            let key = filterKeys[i];
            let elValue = element[key].toLowerCase();
            let fiValue = filter[key].toLowerCase();

            if (elValue.indexOf(fiValue) === -1) {
                areEqual = false;
                break;
            }
        }

        // Return true if basic comparison is done, or if no deep search is required
        if (areEqual) return true;
        if (!deep) return false;

        // Compare array types
        const elementKeys = Object.keys(element);

        for (let i = 0; i < elementKeys.length; i++) {
            let key = elementKeys[i];

            if (element[key] === Array) {
                for (let j = 0; j < element[key].length; j++) {
                    let child = element[key][j];
                    let areEqual = this.compare(child, filter, true);

                    // Deep child found that is equal, stop searching
                    if (areEqual)
                        return true;
                }
            }
        }

        // No childs found
        return false;
    }
}