import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetDistinctWorkOrderRequest implements IRequest<GetDistinctWorkOrderResponse> {
    constructor(init?: Partial<GetDistinctWorkOrderRequest>) {
        Object.assign(this, init);
    }

    id: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.WorkOrders.GetDistinctWorkOrderRequest';
}

export class GetDistinctWorkOrderResponse extends ImversaResponse {
    workOrder: dtos.WorkOrderReferenceDto;
    technicians: dtos.UserAccountReferenceDto[];
    requesters: dtos.UserAccountReferenceDto[];
    requester: dtos.UserAccountReferenceDto;
    residence: dtos.ClientResidenceReferenceDto;
    workOrderLabels: dtos.WorkOrderLabelReferenceDto[];
    allOtherTechnicians: dtos.UserAccountReferenceDto[];

    constructor(init?: Partial<GetDistinctWorkOrderResponse>) {
        super();
        Object.assign(this, init);
    }
} 