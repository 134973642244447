import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class ReadTechniciansFromExcelRequest implements IRequest<ReadTechniciansFromExcelResponse> {
    constructor(init?: Partial<ReadTechniciansFromExcelRequest>) {
        Object.assign(this, init);
    }

    keyString: string;
    public $type: string = 'Imversa.Web.RequestHandlers.Excel.ReadTechniciansFromExcelRequest';
}

export class ReadTechniciansFromExcelResponse extends ImversaResponse {
    usersToAdd: string[];

    constructor(init?: Partial<ReadTechniciansFromExcelResponse>) {
        super();
        Object.assign(this, init);
    }
} 