import * as enums from '../enums';


export class UserDataDto  {

    public userId: number;
    public userName: string;
    public name: string;
    public roles: enums.UserRole[];

    constructor(init?: Partial<UserDataDto>) {
        Object.assign(this, init);
    }
}