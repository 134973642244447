import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'check',
    templateUrl: './check.html'
})
export class Check {
    @Input() checked: boolean;
    @Output() checkedChange = new EventEmitter<boolean>();

    toggle() {
        if (this.checked) {
            this.checked = false;
        } else {
            this.checked = true;
        }

        this.checkedChange.emit(this.checked);
    }
}