import { Injectable } from '@angular/core';
import * as auth from '../auth';
import * as m from '../../models';
import { Dispatcher } from '../http';

@Injectable()
export class TranslateService {
    public resources: any;

    constructor(
        private auth: auth.AuthenticationService,
        private dispatcher: Dispatcher
    ) {
        this.auth
            .userInfo$
            .subscribe(async (userInfo) => {
                if (userInfo == null) return;
                const request = new m.GetTranslationsRequest({ language: userInfo.language });
                const response = await this.dispatcher.send<m.GetTranslationsResponse>(request);
                this.resources = response;
            });
    }

    translate(key: string): string {
        if (this.resources && typeof (key) === 'string')
            return this.byString(this.resources, key);

        return null;
    }

    byString(o: any, s: string): string {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return null;
            }
        }
        return o;
    }
}