import { Routes } from "@angular/router";
import { ImportComponent } from "./import.component";
import { WorkOrderComponent } from "./work-order.component";
import { OverviewComponent } from "./overview.component";
import { TechnicianOverviewComponent } from "./technician-overview.component";

export const routes: Routes = [
    {
        path: '',
        component: WorkOrderComponent,
        children: [
            {
                path: '',
                redirectTo: 'overview',
                pathMatch: 'full'
            },
            {
                path: 'import',
                component: ImportComponent
            },
            {
                path: 'overview',
                component: OverviewComponent
            },
            {
                path: 'technician-overview',
                component: TechnicianOverviewComponent
            }
        ]
    }
];