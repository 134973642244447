import * as enums from '../enums';


export class IdTextDto  {

    public id: number;
    public text: string;

    constructor(init?: Partial<IdTextDto>) {
        Object.assign(this, init);
    }
}