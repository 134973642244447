import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetUsersRequest implements IRequest<GetUsersResponse> {
    constructor(init?: Partial<GetUsersRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.Security.UserAccounts.GetUsersRequest';
}

export class GetUsersResponse extends ImversaResponse {
    users: dtos.UserAccountDto[];
    technicians: dtos.UserAccountDto[];

    constructor(init?: Partial<GetUsersResponse>) {
        super();
        Object.assign(this, init);
    }
} 