<div class="page-wrapper">
    <div class="container-xl">
        <div class="page-header d-print-none">
            <div class="align-items-center">
                <h1 class=" d-flex justify-content-center">
                    Werkbonnen voor {{dateToday | date: 'mediumDate'}}
                </h1>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="page-title">
                                Overzicht
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card mt-2" *ngFor="let workorder of workOrdersToday">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <span class="w-50">
                                <b href="javascript:void(0)" class="fw-bold">
                                    {{workorder.date | date:'dd/MM/yyyy'}} {{workorder.partOfDay | enum | translate}}
                                </b>
                                <a href="javascript:void(0);" class="fw-bold"
                                    (click)="openWorkOrderTechnicianEditModal(workorder)">
                                    {{workorder.clientResidenceResidentFirstName}}
                                    {{workorder.clientResidenceResidentLastName}} </a> - {{workorder.description}}
                            </span>
                            <span>
                                <div class="d-flex justify-content-end">
                                    Techniekers:&nbsp;
                                    <span *ngFor="let t of workorder.technicians; let isLast=last">
                                        {{t.text}}{{!isLast ? '&nbsp;/&nbsp;' : ''}}
                                    </span>
                                </div>
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="w-50">{{workorder.clientResidenceAddressLine}}</span>
                            <span>Afgesproken met: {{workorder.requesterFirstName}}</span>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex">
                          <a (click)=" openCloseBoxWorkOrder(workorder.id)" class="btn btn-primary ms-auto">Afsluiten</a>
                        </div>
                      </div>
                </div>

                <work-order-modal-technician #editWorkOrderModalTech (onChange)="refresh()"></work-order-modal-technician>

                <div class="d-flex justify-content-center mt-3 mb-3">
                    <pagination #pagination [(currentPage)]="currentPage" [total]="total" [pageSize]="pageSize"
                        (currentPageChange)="pageChanged($event)"></pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal #closeBox>
    <div class="app-modal-header">
        <h5 class="modal-title">Werkbon afsluiten</h5>
    </div>
    <div class="app-modal-body">
        <div class="card mb-3">
            <div class="card-body">
                <p> Weet u zeker dat u de werkbon voor klant {{nameClient}} wilt afsluiten? U kan de inhoud van deze werkbon nadien niet meer aanpassen. </p>  
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <div class="btn-list flex-nowrap me-auto">
            <button type="button" class="btn btn-default me-1" (click)="cancelCloseWorkOrder()">
                Annuleren
            </button>
            <button type="button" class="btn btn-primary" (click)="closeWorkOrder()">
                Afsluiten
            </button>
        </div>
    </div>
</app-modal>