import { RouterModule, Routes } from '@angular/router';

import * as login from './login';
import * as main from './main';
import * as c from './core';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full'
    }
];

export const routing = RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'});