import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class PingRequest implements IRequest<PingResponse> {
    constructor(init?: Partial<PingRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.System.PingRequest';
}

export class PingResponse extends ImversaResponse {
    internalDependenciesOk: boolean;
    currentTime: Date;
    isAuthenticated: boolean;
    userData: dtos.UserDataDto;

    constructor(init?: Partial<PingResponse>) {
        super();
        Object.assign(this, init);
    }
} 