import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class EmailRequest implements IRequest<EmailResponse> {
    constructor(init?: Partial<EmailRequest>) {
        Object.assign(this, init);
    }

    email: string;
    public $type: string = 'Imversa.Web.RequestHandlers.System.EmailRequest';
}

export class EmailResponse extends ImversaResponse {
    boodschap: string;

    constructor(init?: Partial<EmailResponse>) {
        super();
        Object.assign(this, init);
    }
} 