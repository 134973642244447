<div class="m-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="m-portlet m-portlet--responsive-mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text m--font-success">
                                Eigenaars
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <div class="btn-group" role="group">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text m--font-success" id="btnGroupAddon">Filter</span></div>
                                <input type="text" class="form-control" placeholder="Familienaam" aria-describedby="btnGroupAddon"
                                    [(ngModel)]="listFilter">
                            </div>


                            <button type="button" class="m-btn btn btn-brand m--font-success" [routerLink]="['/residents/', 'create']">
                                <fa-icon icon="user-plus"></fa-icon>
                            </button>

                        </div>
                    </div>
                </div>

                <ul class="list-group">
                    <li class="list-group-item list-group-item-action flex-column" *ngFor="let resident of filteredResidents; index as i"
                        [attr.data-index]="i"
                        [class.active]="highlightResidents[i]">
                        <div class="d-flex justify-content-between">
                            <label class="label w-25" for="clickedResident{{i}}">
                                <a [routerLink]="['/residents/', resident.id, 'edit']">{{resident.name}} {{resident.familyName}}</a>
                            </label>
                            <p class="w-25"> {{resident.email}}</p>
                            <p class="w-25"> 0{{resident.telephoneNumber}}</p>
                        </div>
                        <small class="text-muted align-self-start">{{resident.address?.cityName}}</small>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>