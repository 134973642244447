import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'

export class UserAccountReferenceDto extends EntityReferenceDto {

    public firstName: string;
    public familyName: string;
    public shortNameTech: string;
    public shortNamePlan: string;
    public status: enums.UserStatus;

    constructor(init?: Partial<UserAccountReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}