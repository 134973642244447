import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as c from '../core';
import * as m from '../models';

@Component({
    selector: 'password',
    templateUrl: './password.html',
})
export class PassWordComponent implements OnInit {
    public user: m.UserAccountDto;
    public sub: any;
    public nieuwPaswoord: string;
    public bevestigPaswoord: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private dispatcher: c.Dispatcher,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(async params => {
            let userId = +params['id']; // (+) converts string 'id' to a number
            const response = await this.dispatcher.send<m.GetDistinctUserResponse>(new m.GetDistinctUserRequest({ id: userId }))
            this.user = response.user;
        });
    }

    async saveNewPassword() {
        const request = new m.UpdatePasswordRequest({
            id: this.user.id,
            newPassword: this.nieuwPaswoord,
            confirmPassword: this.bevestigPaswoord
        });

        const response = await this.dispatcher.send<m.UpdatePasswordResponse>(request)

        if (response.success) {
            this.router.navigate(['/users']);
        }
    }
}