import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
//import { ClientExceptionRequest } from '../contracts/ClientExceptionRequest';

@Injectable()
export class ExceptionService {
    public errorSubject = new BehaviorSubject<any>(null);
    public count: number = 0;

    constructor(
        ) { }

    getErrors(): Observable<any> {
        return this.errorSubject.asObservable();
    }

    public registerError(error: any) {
        this.count++;
        this.logError(error);

        this.errorSubject.next(error);
    }

    private logError(error: any) {
        if (error && error.exceptionMessage) {
            let message = `${error.exceptionType}

${error.message}
${error.exceptionMessage}

Stacktrace
----------
${error.stackTrace}`;

            console.error(message);
        }
        else {
            //It is a client error
            console.error('Exception ', error);

            if (this.count < 10) {
                const cause = '';
                const path = '';

                // TODO: Fix client exception system
                // let request = new ClientExceptionRequest();
                // request.message = error.message;
                // request.cause = cause;
                // request.path = path;

                // //Lazy load Api because else we have a circler reference
                // let api = this.injector.get(Api);
                // if (api !== null) {
                //     api.dispatch(request).subscribe();
                // }
            }
        }
    }
}