import * as enums from '../enums';


export class ImportExcelLineDto  {

    public timeZone: string;
    public name: string;
    public address: string;
    public telephoneNumber: string;
    public description: string;
    public appointmentMaker: string;
    public technicians: string;
    public remark: string;

    constructor(init?: Partial<ImportExcelLineDto>) {
        Object.assign(this, init);
    }
}