import * as enums from '../enums';


export class EntityReferenceDto  {

    public id: number;

    constructor(init?: Partial<EntityReferenceDto>) {
        Object.assign(this, init);
    }
}