import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bytes'
})
export class BytesPipe implements PipeTransform {
    transform(value: any, precision: number): string {
        if (isNaN(parseFloat(value)) || !isFinite(value)) return '-';
        if (typeof precision === 'undefined') precision = 0;
        if (value === 0) return '0 K';
        var units = ['bytes', 'K', 'M', 'G', 'T', 'P'],
            number = Math.floor(Math.log(value) / Math.log(1024));
        return (value / Math.pow(1024, Math.floor(number))).toFixed(precision) + units[number];
    }
}