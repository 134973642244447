import { Component, OnInit } from "@angular/core";

import * as c from '@core';
import * as m from '@models';

@Component({
    templateUrl: 'main.html'
})
export class MainComponent implements OnInit {
    userInfo: any;

    constructor(
        public auth: c.AuthenticationService) { }

    ngOnInit(): void {
        this.userInfo = this.auth.userInfo$;
    }

    async logout() {
        this.auth.logout();
    }
}