import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login.component';
import { PassWordComponent } from './password.component';

export const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'users/:id/change-password',
        component: PassWordComponent
    }
];

export const routing = RouterModule.forRoot(routes);