import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientresidenceNewComponent } from '../clientresidence';

import * as c from '../../core';
import * as m from '../../models';


@Component({
    templateUrl: './contract-detail.html'
})
export class ContractDetailComponent implements OnInit, OnDestroy {
    public contract: m.ContractDto = null;
    public crNew: m.ClientResidenceDto = null;
    public residences: m.ClientResidenceReferenceDto[] = [];
    public requesters: m.UserAccountReferenceDto[] = [];
    public technicians: m.UserAccountReferenceDto[] = [];
    public type = m.ContractType;

    public key: string;
    private sub: any;
    public contractId: number;

    public success: boolean = false;
    public correctCities: any[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dispatcher: c.Dispatcher) { }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (!params) return;
            this.contractId = +params['id']; // (+) converts string 'id' to a number
            this.refresh(this.contractId);
        });
    }

    ngOnDestroy() {
        this.sub.next();
        this.sub.complete();
    }

    public async refresh(contractId: number) {
        let request = new m.GetDistinctContractRequest({ id: this.contractId });
        let response = await this.dispatcher.send<m.GetDistinctContractResponse>(request);
        this.contract = response.contract;
    }

    //Excel opladen
    @ViewChild('myModalUpload')
    public myModalUpload: c.ModalComponent;

    @ViewChild('myModalUploadBadList')
    public myModalUploadBadList: c.ModalComponent;

    addResidenceList() {
        this.myModalUpload.show();
    }

    async handleSaveNewExcelData() {
        let request = new m.ImportExcelAddressListRequest({
            contractId: this.contractId,
            keyString: this.uploadedFile.key
        });

        let response = await this.dispatcher.send<m.ImportExcelAddressListResponse>(request);
        this.success = response.success;
        this.correctCities = response.correctCities;

        if (!response.success) {
            this.uploadedFile = null;
            this.myModalUploadBadList.show();
            this.refresh(this.contractId);
        }
        else { this.uploadedFile = null; this.refresh(this.contractId); }
    }

    public hideMyModalUpload() {
        this.myModalUpload.hide();
        this.uploadedFile = null;
    }

    public uploadedFile: c.FileInfo;

    public onFileUploaded(fileInfo: c.FileInfo) {
        this.uploadedFile = fileInfo;
    }

    //Residence
    @ViewChild('myModal')
    public myModal: c.ModalComponent;
    @ViewChild('crDetail')
    public crDetail: ClientresidenceNewComponent;

    public async addResidence() {
        await this.crDetail.showNew();
        this.myModal.show();
    }

    public handleSaveIsNewResidence = async () => {
        await this.crDetail.saveResidence(this.contractId);
        this.myModal.hide();
        this.refresh(this.contractId);
    }

    //Contract 
    public handleSaveIsNotNew = async () => {
        var adres = new m.AddressMiniDto({
            street: this.contract.address.street,
            number: this.contract.address.number,
            bus: this.contract.address.bus,
            cityId: this.contract.address.cityId
        });

        const request = new m.UpdateContractRequest({
            id: this.contract.id,
            type: this.contract.type,
            reference: this.contract.reference,
            contactAddress: adres,
            startDate: this.contract.startDate,
            endDate: this.contract.endDate,
        })

        await this.dispatcher.send<m.UpdateContractResponse>(request);
        this.returnToOverview();
    }

    returnToOverview() {
        this.router.navigate(['contracts']);
    }
}
