import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as o from "rxjs/operators";

import * as NProgress from "nprogress";

NProgress.configure({ showSpinner: false });


@Injectable()
export class LazyLoaderInterceptor implements HttpInterceptor  {
    public static parallelCounter = 0;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.start();

        return next.handle(req)
            .pipe(
                o.finalize(() => this.done())
            );
    }

    start() {
        LazyLoaderInterceptor.parallelCounter++;
        setTimeout(() => { this.reviewStart(); }, 300);
    }

    done() {
        LazyLoaderInterceptor.parallelCounter--;
        setTimeout(() => { this.reviewDone(); }, 500);
    }

    reviewStart() {
        if (LazyLoaderInterceptor.parallelCounter > 0) {
            if (NProgress) {
                NProgress.start();
            }
        }
    }

    reviewDone() {
        if (LazyLoaderInterceptor.parallelCounter === 0) {
            if (NProgress)
                NProgress.done();
        }
    }
}