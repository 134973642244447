import * as Dtos from './index';

export class ValidationMessage
{
    constructor(init?: Partial<ValidationMessage>) {
        Object.assign(this, init);
    }

    public validationLevel : Dtos.ValidationLevel;
    public code : string;
    public message : string;
}