import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as c from '../../core';
import * as m from '../../models';

@Component({
    selector: 'residentdetail',
    templateUrl: './residentdetail.html'
})
export class ResidentdetailComponent {
    public resident: m.ResidentDto;

    private sub: any;
    public isNew: boolean;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dispatcher: c.Dispatcher
    ) { }

    ngOnDestroy() {
        this.sub.next();
        this.sub.complete();
    }

    refresh() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (!params) return;
            let residentId = +params['id']; 
            this.isNew = isNaN(residentId);

            if (!this.isNew) {
                this.openResident(residentId)
            }
            else {
                this.resident = new m.ResidentDto({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: ''
                });
            }
        });
    }

    public async openResident(residentId: number){
        // let request = new m.GetDistinctResidentRequest({ id: residentId });
        // let response = await this.dispatcher.send<m.GetDistinctResidentResponse>(request);

        // this.resident = response.resident; 
    }

    public handleSaveIsNewResident = async () => {
        // const request = new m.CreateResidentRequest({
        //     name: this.resident.name,
        //     familyName: this.resident.familyName,
        //     email: this.resident.email,
        //     telephoneNumber: this.resident.telephoneNumber
        // });

        // await this.dispatcher.send<m.CreateResidentResponse>(request);
        // this.returnToOverview();
    }

    public handleSaveIsExistingResident = async () => {
        // const request = new m.UpdateResidentRequest({
        //     id: this.resident.id,
        //     name: this.resident.name,
        //     familyName: this.resident.familyName,
        //     email: this.resident.email,
        //     telephoneNumber: this.resident.telephoneNumber
        // });

        // await this.dispatcher.send<m.UpdateResidentResponse>(request);
        // this.returnToOverview();
    }

    returnToOverview() {
        this.router.navigate(['residents']);
    }

}
