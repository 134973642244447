import { forwardRef, Directive, ElementRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
    selector: '[number-input]',
    host: { '(change)': 'onChange($event)' },
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NumberInput), multi: true }
    ]
})
export class NumberInput implements ControlValueAccessor {
    public innerValue: string;

    @Input() precision: number = 5;

    constructor(private elRef: ElementRef) {
    }

    //How the callback function will look like
    public onTouchedCallback: () => void = () => { };
    public onChangeCallback: (newValue: any) => void = () => { };

    onChange($event: any): void {
        let value = $event.target.value || '';
        value = value.replace(',', '.');

        let newValue: number;
        if (this.precision !== undefined) {
            newValue = this.round(+value);
        } else {
            newValue = +value;
        }
        if (isNaN(newValue)) {
            newValue = null;
        }

        this.onChangeCallback(newValue);
        this.writeValue(newValue);
    }

    writeValue(value: number): void {
        let textValue = '';
        if (value !== undefined && value !== null) {
            if (this.precision !== undefined) {
                textValue = value.toFixed(this.determinePrecision(value));
            } else {
                textValue = value.toString()
            }
            textValue = textValue.replace('.', ',');
        }

        this.elRef.nativeElement.value = textValue;
    }

    private determinePrecision(value: number) : number {
        var chunk = value.toString().split('.');
        var decimalSize = chunk.length >= 2 ? chunk[1].length : 0;

        if (decimalSize < this.precision) {
            return decimalSize <= 2 ? 2 : decimalSize;
        }

        return this.precision;
    }

    private round(nmbr: number) {
        let multiplier = 1;
        let floatingPointFix = 0.001;
        for (let i = 0; i < this.precision; i++) {
            multiplier *= 10;
        }

        //We do +0.0001 because of floating point hell
        return Math.round((nmbr * multiplier) + + 0.00001) / multiplier;
    }

    //Functions called by other party to receive the onchange events!
    registerOnChange = (fn: any) => this.onChangeCallback = fn;
    registerOnTouched = (fn: any) => this.onTouchedCallback = fn;
}
