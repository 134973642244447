import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgOption } from '@ng-select/ng-select';
import { Dispatcher } from '../../core';

import * as m from '../../models';

@Component({
    selector: 'address-detail',
    template: `
     <form class="m-form m-form--label-align-right">
        <div class="m-form__section">
            <div class="form-group m-form__group row">
                <label for="straat" class="col-lg-3 col-form-label">Straat:</label>
                <div class="col-lg-6">
                    <input id="straat" name="straat" type="text" class="form-control m-input" placeholder="Straat"
                        [(ngModel)]="object.address.street">
                </div>
            </div>

            <div class="form-group m-form__group row">
                <label for="nummer" class="col-lg-3 col-form-label">Nr:</label>
                <div class="col-lg-2">
                    <input id="nummer" name="nummer" type="text" class="form-control m-input" placeholder="0"
                        [(ngModel)]="object.address.number">
                </div>
                <label for="bus" class="col-lg-2 col-form-label">Bus:</label>
                <div class="col-lg-2">
                    <input id="bus" name="bus" type="text" class="form-control m-input" placeholder="0" [(ngModel)]="object.address.bus">
                </div>
            </div>

            <div class="form-group m-form__group row">
                <label for="postcodes" class="col-lg-3 col-form-label">Gemeente</label>
                <div class="col-lg-6" *ngIf="data">
                    <ng-select class="form-control m-input"
                    [items]="data" 
                    bindLabel="postNaam" 
                    [(ngModel)]="selectedCityId" 
                    bindValue="id" 
                    name="postcodes"
                    (change)="emitChangedId($event)"
                    (search)="searchItem($event)">
                    </ng-select>
                </div>
            </div>
        </div>
</form>
`
})

export class AddressdetailComponent {
    public postalCodes: NgOption[] = [];
    public data: any[] = [];
    public selectedCity: any;

    constructor(private dispatcher: Dispatcher) {
    }

    @Input()
    public object: any;

    public _selectedCityId: number;

    @Output()
    public selectedCityIdChange: EventEmitter<number> = new EventEmitter<number>();

    @Input() set selectedCityId(value: number) {
        this._selectedCityId = value;
    }

    public emitChangedId(event): void {
        this._selectedCityId = event.id;
        this.selectedCityIdChange.emit(this._selectedCityId);
    }

    async getCities(zoekterm: string) {
        var response = await this.dispatcher.send<m.GetCitiesResponse>(new m.GetCitiesRequest({ searchTerm: zoekterm }));
        this.postalCodes = response.cities;

        this.data = this.postalCodes.map(obj => {
            var newObj = {};
            newObj["id"] = obj.id;
            newObj["postNaam"] = obj.postalCode + "-" + obj.name;
            return newObj;
        });
    }

    async searchItem(item: any) {
        if (!item) {
            await this.getCities(item);
        }
    }
}