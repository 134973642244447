import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class CloseWorkOrderByTechnicianRequest implements IRequest<CloseWorkOrderByTechnicianResponse> {
    constructor(init?: Partial<CloseWorkOrderByTechnicianRequest>) {
        Object.assign(this, init);
    }

    workOrderId: number;
    public $type: string = 'Imversa.Web.RequestHandlers.Performance.WorkOrders.CloseWorkOrderByTechnicianRequest';
}

export class CloseWorkOrderByTechnicianResponse extends ImversaResponse {

    constructor(init?: Partial<CloseWorkOrderByTechnicianResponse>) {
        super();
        Object.assign(this, init);
    }
} 