

export enum AbsenceType {
    sickness = 1001,
    vacation = 1002,
    overtime = 1003,
}

export namespace AbsenceType {
    export function keys(): number[] {
        return [1001, 1002, 1003];
    }
}

document['enums'][1001] = 'common.absenceType_Sickness';
document['enums'][1002] = 'common.absenceType_Vacation';
document['enums'][1003] = 'common.absenceType_Overtime';