import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class DeleteResidenceRequest implements IRequest<DeleteResidenceResponse> {
    constructor(init?: Partial<DeleteResidenceRequest>) {
        Object.assign(this, init);
    }

    id: number;
    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.ClientResidences.DeleteResidenceRequest';
}

export class DeleteResidenceResponse extends ImversaResponse {

    constructor(init?: Partial<DeleteResidenceResponse>) {
        super();
        Object.assign(this, init);
    }
} 