import * as enums from '../enums';
import { EntityReferenceDto } from './EntityReferenceDto'

export class CityReferenceDto extends EntityReferenceDto {


    constructor(init?: Partial<CityReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}