import * as enums from '../enums';


export class EntityDto  {

    public id: number;

    constructor(init?: Partial<EntityDto>) {
        Object.assign(this, init);
    }
}