import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import * as c from '../../core';
import * as m from '../../models';
import { Dispatcher } from '../../core';


@Component({
    selector: 'clientresidence-new',
    template: `
  <div *ngIf="cr">
      <form #form="ngForm" class="d-flex flex-column" novalidate>
      <div class="m-portlet__body">
      <div class="m-form__section m-form__section--first">
          <div class="form-group m-form__group row">
              <label for="naam" class="col-lg-3 col-form-label">Naam:</label>
              <div class="col-lg-6">
              <input id="naam" name="naam" type="text" class="form-control 
              m-input" placeholder="Voornaam" [(ngModel)]="cr.resident.firstName">
          </div>
          </div>
          <div class="form-group m-form__group row">
                <label for="telefoon" class="col-lg-3 col-form-label">Telefoon:</label>
                <div class="col-lg-6">
                <input id="telefoon" name="telefoon" type="text" class="form-control m-input"
                [(ngModel)]="cr.resident.phone">
                </div>
            </div>
            <address-detail [object]='cr' [(selectedCityId)]="selectedId"></address-detail>
          </div>
          </div>
      </form>
  </div>`
})
export class ClientresidenceNewComponent {
    constructor(private dispatcher: Dispatcher) {
    }
    public selectedId: number;

    @Input()
    public cr: m.ClientResidenceDto;

    @Input()
    public contractId: number;

    @Output()
    public crChange: EventEmitter<m.ClientResidenceDto> = new EventEmitter<m.ClientResidenceDto>();

    public async saveResidence(id: number) {
        let adress = new m.AddressMiniDto({
            street: this.cr.address.street,
            number: this.cr.address.number,
            bus: this.cr.address.bus,
            cityId: this.selectedId
        });

        let resident = new m.ResidentReferenceDto({
            firstName: this.cr.resident.firstName,
            lastName: this.cr.resident.lastName,
            phone: this.cr.resident.phone
        })

        const request = new m.CreateResidenceRequest({
            address: adress,
            resident: resident,
            contractId: id
        })

        let response = await this.dispatcher.send<m.CreateResidenceResponse>(request);
        this.cr = response.residence;
        this.crChange.emit(this.cr);
    }

    public showNew() {
        this.cr = new m.ClientResidenceDto({
            address: new m.AddressMiniDto({}),
            contract: new m.ContractReferenceDto({}),
            resident: new m.ResidentReferenceDto({})
        });


    }

}