import * as enums from '../enums';
import { EntityDto } from './EntityDto'

export class SessionLogReferenceDto extends EntityDto {

    public userAccountId: number;
    public userAction: enums.UserActionLog;
    public timeStamp: Date;
    public ipAddress: string;
    public comment: string;

    constructor(init?: Partial<SessionLogReferenceDto>) {
        super(init);
        Object.assign(this, init);
    }
}