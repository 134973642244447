import { Component, Input } from '@angular/core';
import * as m from '../../models';

@Component({
    selector: 'clientresidence-overview',
    template: `
        <a *ngIf="residence" 
            [routerLink]="['/contracts/detail', residence.contractId, 'residence', residence.id]" 
            class="m-list-search__result-item" 
            [class.active]="highlightResidence" 
            (click)="toggleResidenceField()">
            <span class="m-list-search__result-item-icon"><fa-icon icon ="home"></fa-icon></span>
            <span class="m-list-search__result-item-text"> 
                <p>Adres: {{residence.addressLine}}</p>
                <p>Bewoner: {{residence.residentFirstName}} {{residence.residentLastName}}</p>
            </span>
        </a>
`
})
export class ClientresidenceoverviewComponent {
    @Input()
    residence: m.ClientResidenceReferenceDto = new m.ClientResidenceReferenceDto();

    highlightResidence: boolean = false;

    public toggleResidenceField() {
        this.highlightResidence = !this.highlightResidence;
    }

    constructor() { }


}

