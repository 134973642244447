import * as enums from '../enums';
import * as dtos from '../dtos';
import { ImversaResponse } from '../../core/http';

export class GetResidencesRequest implements IRequest<GetResidencesResponse> {
    constructor(init?: Partial<GetResidencesRequest>) {
        Object.assign(this, init);
    }

    public $type: string = 'Imversa.Web.RequestHandlers.CustomerRelations.ClientResidences.GetResidencesRequest';
}

export class GetResidencesResponse extends ImversaResponse {
    residences: dtos.ClientResidenceReferenceDto[];

    constructor(init?: Partial<GetResidencesResponse>) {
        super();
        Object.assign(this, init);
    }
} 