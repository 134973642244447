import * as enums from '../enums';
import { EntityDto } from './EntityDto'

export class CityDto extends EntityDto {

    public postalCode: string;
    public name: string;

    constructor(init?: Partial<CityDto>) {
        super(init);
        Object.assign(this, init);
    }
}