<!-- Edit formulier -->
<div class="container" *ngIf="cr">
    <div class="m-subheader ">
        <div class="d-flex align-items-center">
            <div class="mr-auto">
                <h3 class="m-subheader__title m-subheader__title">Residentie bij contract {{cr.contract.reference}}</h3>
            </div>
        </div>
    </div>

    <div class="m-content">
        <form #form="ngForm" class="m-form m-form--label-align-right" novalidate>
            <div class="m-portlet__body">
                <div class="m-form__section m-form__section--first">
                    <div class="form-group m-form__group row">
                        <label for="naam" class="col-lg-3 col-form-label">Naam:</label>
                        <div class="col-lg-3">
                            <input id="firstName" name="firstName" type="text" class="form-control m-input" placeholder="Voornaam" [(ngModel)]="cr.resident.firstName">
                        </div>
                        <div class="col-lg-3">
                            <input id="lastName" name="lastName" type="text" class="form-control m-input" placeholder="Achternaam" [(ngModel)]="cr.resident.lastName">
                        </div>
                    </div>

                    <div class="form-group m-form__group row">
                        <label for="Telefoon" class="col-lg-3 col-form-label">Telefoon:</label>
                        <div class="col-lg-6">
                            <input id="telefoon" name="telefoon" type="text" class="form-control m-input"
                                 [(ngModel)]="cr.resident.phone">
                        </div>
                    </div>

                    <address-detail [object]='cr'></address-detail>
                </div>
            </div>
        </form>

        <btn-save [onSave]="handleSaveIsNotNew"></btn-save>
        <button type="button" class="btn btn-default" (click)="returnToDetail()">Terug</button>
    </div>
</div>