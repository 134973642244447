import { Component } from '@angular/core';

import * as c from '../../core';
import * as m from '../../models';

@Component({
    selector: 'sandbox',
    templateUrl: './sandbox.html',
    styles: [`
    .bd-example {
        padding: 1.5rem;
        margin: 1rem -15px 0;
        margin-right: 0;
        margin-left: 0;
        position: relative;
        border: solid #f7f7f9;
        border-width: .2rem;
    }
    
    .highlight {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 1rem;
        background-color: #f7f7f9;
        padding: 1.5rem;
    }

    .highlight pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
        display: block;
        font-size: 87.5%;
        color: #212529;
    }
    
    .highlight pre code {
        font-size: inherit;
        color: #212529;
        word-break: normal;
    }

    `]
})
export class SandboxComponent {
    constructor(
        private dispatcher: c.Dispatcher
    ) { }

    public saveString: string = `// Html
<btn-save [onSave]="handleSave"></btn-save>

// Component
public handleSave = async () => {
    const request = new m.PingRequest();
    await this.dispatcher.send<m.PingResponse>(request);
}`;
    public handleSave = async () => {
        return new Promise(resolve => setTimeout(resolve, 1500));
    }

    public deleteString: string = `// Html
<btn-delete [onDelete]="handleDelete"><btn-delete>

// Component
public handleDelete = async () => {
    const request = new m.PingRequest();
    await this.dispatcher.send<m.PingResponse>(request);
}`;
    public handleDelete = async () => {
        return new Promise(resolve => setTimeout(resolve, 1500));
    }

    public addString: string = `// Html
    <btn-add [addToepassing]="handleAdd"><btn-add>
    
    // Component
    public handleAdd = async () => {
        const request = new m.PingRequest();
        await this.dispatcher.send<m.PingResponse>(request);
    }`;
        public handleAdd = async () => {
            return new Promise(resolve => setTimeout(resolve, 1500));
        }
    

    public pingResponse: any;

    public async ping() {
        const request = new m.PingRequest();
        this.pingResponse = await this.dispatcher.send<m.PingResponse>(request);
    }

    public emailResponse: any;

    public async email(){
        const request = new m.EmailRequest();
        this.emailResponse = await this.dispatcher.send<m.EmailResponse>(request);
    }

    public modalString: string = `// Html
<button class="btn btn-default" (click)="myModal.show()">Open modal</button>

<app-modal #myModal>
    <div class="app-modal-header">
        <h5 class="modal-title">Modal title</h5>
    </div>
    <div class="app-modal-body">
        <p>Modal body text goes here.</p>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-default" (click)="myModal.hide()">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
    </div>
</app-modal>`;

    public disableDateTime = false;
    public startDate: string = '';
    public datetimeString: string = `// Html
<app-datetime [(ngModel)]="selectedDate"></app-datetime>

<label>{{selectedDate | date}}</label>`;
    public resetStartDate() {
        this.startDate = new Date().toISOString();
    }

    public throwServerException = async () => {
        await this.dispatcher.send<m.ServerExceptionResponse>(new m.ServerExceptionRequest());
    }

    public synchronizeTeamLeader = async () => {
        await this.dispatcher.send<m.SynchronizeTeamLeaderResponse>(new m.SynchronizeTeamLeaderRequest());
    }

    public throwClientException() {
        throw 'Dit is een client exception';
    }

    public demoFile : m.GenerateDemoFileResponse = null;

    public generateTempFile = async () => {
        this.demoFile = await this.dispatcher.send<m.GenerateDemoFileResponse>(new m.GenerateDemoFileRequest());
    }

    public uploadedFile: c.FileInfo;

    public onFileUploaded(fileInfo: c.FileInfo) {
        this.uploadedFile = fileInfo;
    }
}

